<template>
  <div class="contentright-inner">
    <!-- navi: start -->
    <div class="contentright-nav">
      <div class="iconbutton mv15" @click="closeRight()">
        <font-awesome-icon
          :icon="['fal', 'arrow-alt-left']"
          size="lg"
        ></font-awesome-icon>
      </div>
      <div v-if="mode.view">
        <div class="iconbutton mv15" @click="switchMode('edit')">
          <font-awesome-icon
            :icon="['fal', 'edit']"
            size="lg"
          ></font-awesome-icon>
        </div>
      </div>
      <div v-if="mode.edit">
        <div class="iconbutton mv15" @click="switchMode('view')">
          <font-awesome-icon
            :icon="['fal', 'eye']"
            size="lg"
          ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="goalSave()">
          <font-awesome-icon
            :icon="['fal', 'save']"
            size="lg"
          ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="goalDelete()">
          <font-awesome-icon
            :icon="['fal', 'trash']"
            size="lg"
          ></font-awesome-icon>
        </div>
      </div>
    </div>
    <!-- navi: end -->
    <div class="contentright-body">
      <div class="contentright-body-header"></div>
      <div class="contentright-body-body scrolly m9">
        <div v-if="mode.view">
          <!-- basic: start -->
          <section></section>
          <h3 class="txth2">{{ ingoal.goaltitle }}</h3>
          <h3 class="txth2" v-if="ingoal.goaltitle === ''">
            No name given to this {{ ingoal.goaltype }}
          </h3>
          <div>{{ ingoal.description }}</div>
          <div class="sp20"></div>

          <!-- basic: end -->

          <!-- connections: start  -->
          <section>
            <!-- available-to: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth4">Available to team(s)</div>
            </div>
            <div class="dtable-row tsk vlgreybg mv7" v-for="av in gl.availableto" :key="av._id">
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong>
              </div>
            </div>
            <div class="tsk vlgreybg mv7" v-if="gl.availableto.length === 0">
              Only available to me
            </div>
            <!-- available-to: ends -->
          </section>
          <!-- connections: end  -->
        </div>

        <div v-if="mode.edit">
          <section>
            <div class="isFlex isFlexAlign mb7">
              <div class="txth4">{{ gl.goaltitle }}</div>
              <div class="txth4" v-if="gl.goaltitle === ''">
                No name given to this {{ gl.goaltype }}
              </div>
            </div>
            <!-- basic: start -->
            <b-field label="Is this a goal or a project?">
              <b-select
                placeholder="Select a type"
                v-model="gl.goaltype"
                expanded
              >
                <option value="Goal">Goal</option>
                <option value="Project">Project</option>
              </b-select>
            </b-field>
            <b-field :label="gl.goaltype + 'title'">
              <b-input v-model="gl.goaltitle" type="text" rows="2"></b-input>
            </b-field>
            <b-field label="Additional description">
              <b-input
                v-model="gl.description"
                type="textarea"
                rows="2"
              ></b-input>
            </b-field>

            <b-field label="Colour">
              <compact-picker v-model="colors" @input="changeColor" />
            </b-field>

            <!-- availableto: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Availble to team(s)</div>
              <div>
                <div class="iconbutton" @click="selectPerson()">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              class="dtable-row tsk lgreybg mv7"
              v-for="(av, index) in gl.availableto"
              :key="av._id"
            >
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong>
              </div>
              <div class="dtable-row-right">
                <div class="iconbutton" @click="availabletoDelete(index)">
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div class="tsk lgreybg mv7" v-if="gl.availableto.length === 0">
              Only available to me
            </div>
            <!-- availableto: ends -->

            <b-field label="Client">
              <div class="tsk inputbr p4 isFlex">
                <div class="isFlexGrow">
                  <span v-if="gl.clientid === ''">No client selected</span>
                  <span v-else>{{ gl.client }}</span>
                </div>
                <div class="iconbutton" @click="selectClient()">
                  <font-awesome-icon
                    :icon="['fal', 'id-card']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </b-field>
            <b-field label="Parent" v-if="gl.goaltype !== 'Goal'">
              <b-select
                placeholder="Select parent"
                expanded
                v-model="gl.childof"
              >
                <option
                  v-for="goal in GoalList"
                  :value="goal._id"
                  :key="goal._id"
                >
                  {{ goal.goaltitle }}
                </option>
              </b-select>
            </b-field>

            <b-field label="Why?">
              <b-input v-model="gl.why" type="textarea" rows="5"></b-input>
            </b-field>
            <b-field label="Expected outcome?">
              <b-input v-model="gl.outcome" type="textarea" rows="5"></b-input>
            </b-field>
            <b-field label="Worst Case">
              <b-input
                v-model="gl.worstcase"
                type="textarea"
                rows="5"
              ></b-input>
            </b-field>
            <!-- basic: end -->
          </section>

          <section>
            <b-field label="When do you want to achieve this by?" class="mt11">
              <b-datepicker
                v-model="DueDate"
                placeholder="Click to select..."
                icon="calendar"
              >
                <button
                  class="button is-primary is-small"
                  @click="DueDate = new Date()"
                >
                  <b-icon icon="calendar-day"></b-icon>
                  <span>Today</span>
                </button>

                <button
                  class="button is-danger is-small"
                  @click="DueDate = null"
                >
                  <b-icon icon="times"></b-icon>
                  <span>Clear</span>
                </button>
              </b-datepicker>
            </b-field>
            <div class="mt11" v-if="gl.mondaysettings">
              <b-field label="Track on calendar">
                <b-select placeholder="Select..." v-model="gl.oncal" expanded>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </b-select>
              </b-field>
              <div class="txtbold mb7">
                What days of the week can you work on this {{ gl.goaltype }} and
                for how long every day?
              </div>
              <!-- monday -->
              <div class="txtbold mb7">Monday</div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div><b-checkbox v-model="gl.monday"></b-checkbox></div>
                <div class="p5">H</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.mondaysettings.hours"
                    size="is-small"
                    type="is-light"
                  ></b-numberinput>
                </div>
                <div class="p5">M</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.mondaysettings.minutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                  ></b-numberinput>
                </div>
              </div>
              <!-- tuesday -->
              <div class="txtbold mb7">Tuesday</div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div><b-checkbox v-model="gl.tuesday"></b-checkbox></div>
                <div class="p5">H</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.tuesdaysettings.hours"
                    size="is-small"
                    type="is-light"
                  ></b-numberinput>
                </div>
                <div class="p5">M</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.tuesdaysettings.minutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                  ></b-numberinput>
                </div>
              </div>
              <!-- wednesday -->
              <div class="txtbold mb7">Wednesday</div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div><b-checkbox v-model="gl.wednesday"></b-checkbox></div>
                <div class="p5">H</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.wednesdaysettings.hours"
                    size="is-small"
                    type="is-light"
                  ></b-numberinput>
                </div>
                <div class="p5">M</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.wednesdaysettings.minutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                  ></b-numberinput>
                </div>
              </div>
              <!-- thursday -->
              <div class="txtbold mb7">Thursday</div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div><b-checkbox v-model="gl.thursday"></b-checkbox></div>
                <div class="p5">H</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.thursdaysettings.hours"
                    size="is-small"
                    type="is-light"
                  ></b-numberinput>
                </div>
                <div class="p5">M</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.thursdaysettings.minutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                  ></b-numberinput>
                </div>
              </div>
              <!-- friday -->
              <div class="txtbold mb7">Friday</div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div><b-checkbox v-model="gl.friday"></b-checkbox></div>
                <div class="p5">H</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.fridaysettings.hours"
                    size="is-small"
                    type="is-light"
                  ></b-numberinput>
                </div>
                <div class="p5">M</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.fridaysettings.minutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                  ></b-numberinput>
                </div>
              </div>
              <!-- saturday -->
              <div class="txtbold mb7">Saturday</div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div><b-checkbox v-model="gl.saturday"></b-checkbox></div>
                <div class="p5">H</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.saturdaysettings.hours"
                    size="is-small"
                    type="is-light"
                  ></b-numberinput>
                </div>
                <div class="p5">M</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.saturdaysettings.minutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                  ></b-numberinput>
                </div>
              </div>
              <!-- sunday -->
              <div class="txtbold mb7">Sunday</div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div><b-checkbox v-model="gl.sunday"></b-checkbox></div>
                <div class="p5">H</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.sundaysettings.hours"
                    size="is-small"
                    type="is-light"
                  ></b-numberinput>
                </div>
                <div class="p5">M</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="gl.sundaysettings.minutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                  ></b-numberinput>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="contentright-body-footer"></div>
    </div>
  </div>
</template>

<script>
import { Compact } from "vue-color";
import store from "../store";
import { EventBus } from "../event-bus.js";
import { dbFunctions } from "../mixins/dbMixins.js";
import { windowSplit2 } from "../mixins/windowSplit.js";
import ModalPeopleList from "@/components/ModalPeopleList.vue";
import ModalForm from "@/components/ModalForm.vue";
export default {
  mixins: [dbFunctions, windowSplit2],
  name: "Goal",
  props: {
    ingoal: Object,
    inmode: String,
  },

  components: {
    "compact-picker": Compact,
  },

  watch: {
    ingoal: function(newVal, oldVal) {
      // watch it
      this.gl = newVal;
      this.switchMode("view");
      this.loadGoal();
    },
  },

  data() {
    return {
      user: store.state.user,
      gl: this.ingoal,
      GoalList: [],
      mode: {
        view: false,
        edit: true,
      },
      naviview: {},
      naviedit: {
        general: true,
        desc: true,
        steps: true,
      },
      TeamLive: localStorage.getItem("TeamLive"),
      TeamLiveName: localStorage.getItem("TeamLiveName"),
      TeamLiveColour: localStorage.getItem("TeamLiveColour"),

      DueDate: null,
      colors2: {
        hex: "#194d33",
        hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
        hsv: { h: 150, s: 0.66, v: 0.3, a: 1 },
        rgba: { r: 25, g: 77, b: 51, a: 1 },
        a: 1,
      },
      colors: {
        hex: "#CCCCCC",
      },
    };
  },

  created() {
    EventBus.$on("Assign_Client_Goal", (data) => {
      this.assignClient(data);
    });
    EventBus.$on("Select_Person_Goal", (data) => {
      this.availableTo(data);
    });
    EventBus.$on("team_changed", (data) => {
      this.searchField = "";
      this.getTeam();
      this.loadGoal();
    });
    this.getGoals();
    this.loadGoal();
  },

  methods: {
    switchMode(mode) {
      this.mode.view = false;
      this.mode.edit = false;
      this.mode[mode] = true;
    },

    closeRight() {
      EventBus.$emit("goals_close_right", this.gl);
    },

    getTeam() {
      this.TeamLive = localStorage.getItem("TeamLive");
      this.TeamLiveName = localStorage.getItem("TeamLiveName");
      this.TeamLiveColour = localStorage.getItem("TeamLiveColour");
      this.getGoals();
    },

    async getGoals() {
      let query = { deleted: false };
      if (this.TeamLive !== "") {
        let query = { deleted: false, "availableto._id": this.TeamLive };
      }
      const options = { fields: { goaltitle: 1, goalcolor: 1 } };
      const GoalListIN = await this.getData(
        "goals",
        query,
        options,
        "none",
        "none",
        "searchextended"
      );
      this.GoalList.push({ _id: "", goaltitle: "No parent", goalcolor: "" });
      for (const g of GoalListIN) {
        if (g._id !== this.gl._id) {
          this.GoalList.push(g);
        }
      }
    },

    async loadGoal() {
      if (this.inmode === "new") {
        this.gl = this.ingoal;
        if (this.gl.goalcolor === "") {
          this.gl.goalcolor = "#CCCCCC";
        }
        this.colors.hex = this.gl.goalcolor;

        setTimeout(() => {
          this.switchMode("edit");
        }, 1000);

        return;
      }
      // set style
      if (this.gl.goalcolor === "") {
        this.gl.goalcolor = "#CCCCCC";
      }
      this.colors.hex = this.gl.goalcolor;

      const glin = await this.getData(
        "goals",
        { _id: this.gl._id },
        {},
        "none",
        "none",
        "searchextended"
      );
      this.gl = glin[0];
      if (!this.gl.goaltype) {
        this.gl.goaltype = "Goal";
      }
      if (!this.gl.oncal) {
        this.gl.oncal = "No";
      }
      if (!this.gl.outcome) {
        this.gl.outcome = "";
      }
      if (!this.gl.worstcase) {
        this.gl.worstcase = "";
      }
      if (!this.gl.mondaysettings) {
        this.gl.mondaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.gl.tuesdaysettings) {
        this.gl.tuesdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.gl.wednesdaysettings) {
        this.gl.wednesdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.gl.thursdaysettings) {
        this.gl.thursdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.gl.fridaysettings) {
        this.gl.fridaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.gl.saturdaysettings) {
        this.gl.saturdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.gl.sundaysettings) {
        this.gl.sundaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.gl.grindhoursinday) {
        this.gl.grindhoursinday = 6;
      }
      if (!this.gl.grinddaysinmonth) {
        this.gl.grinddaysinmonth = 21;
      }
      if (!this.gl.grinddaysinweek) {
        this.gl.grinddaysinweek = 5;
      }
      if (!this.gl.grindweeksinyear) {
        this.gl.grindweeksinyear = 52;
      }
      // dates
      if (this.gl.duedate !== "") {
        this.DueDate = new Date(this.gl.duedate);
      }
    },

    // change: colour
    changeColor(value) {
      this.gl.goalcolor = value.hex;
    },

    // availableto:start
    selectPerson(mode, row, useIndex) {
      const that = this;
      this.modalA = this.$buefy.modal.open({
        parent: this,
        component: ModalPeopleList,
        hasModalCard: true,
        props: {
          showOpen: false,
          showSelect: true,
          showCheckBox: false,
          hideHeader: true,
          hideRefresh: true,
          hideFilter: true,
          hideAdd: true,
          hideFooter: true,
          listPersonType: "Team",
          listClients: false,
          listSuppliers: false,
          listProspects: false,
          listContacts: false,
          listFriends: false,
          callBus: "Select_Person_Goal",
          gl: that.gl,
        },
      });
    },

    availableTo(row) {
      const newrow = { _id: row._id, firstname: row.firstname };
      this.gl.availableto.push(newrow);
      this.goalSave();
      this.modalA.close();
    },

    availabletoDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>remove access</b> this team? This action cannot be undone.",
        confirmText: "Remove Team",
        type: "is-danger",
        hasIcon: true,
        icon: "exclamation",
        onConfirm: () => {
          this.gl.availableto.splice(index, 1);
          this.$buefy.toast.open("Team Removed!");
          this.updata("goals", this.gl, "none", {}, false);
        },
      });
    },
    // availableto:end

    // client:start
    selectClient() {
      const that = this;
      this.modalA = this.$buefy.modal.open({
        parent: this,
        component: ModalPeopleList,
        hasModalCard: true,
        props: {
          showOpen: false,
          showSelect: true,
          showCheckBox: false,
          hideHeader: true,
          hideRefresh: true,
          hideFilter: true,
          hideAdd: true,
          hideFooter: true,
          listPersonType: "",
          listClients: false,
          listSuppliers: false,
          listProspects: false,
          listContacts: false,
          listFriends: false,
          callBus: "Assign_Client_Goal",
          gl: that.gl,
        },
      });
    },

    assignClient(data) {
      let usename = data.firstname;
      if (data.persontype === "Person") {
        usename = `${data.firstname} ${data.lastname}`;
      }
      this.gl.clientid = data._id;
      this.gl.client = usename;

      this.modalA.close();
    },
    // client:end

    async goalSave() {
      if (this.DueDate !== null) {
        const inst = this.getDateFull(this.DueDate, "none", "none", 0);
        this.gl.duedate = inst.db;
        this.gl.duedatets = inst.tsm;
      } else {
        this.gl.duedate = "";
        this.gl.duedatets = 0;
      }

      const updt = await this.updata("goals", this.gl, "none", {}, false);
      EventBus.$emit("goals_reload", this.gl);

      this.$buefy.toast.open("Goal saved!");
    },

    goalDelete() {},
  },
};
</script>
