/* eslint-disable vue/require-prop-type-constructor */
<template>
  <div class="contentright-inner">
    <!-- navi: start -->
    <div class="contentright-nav">
      <div class="iconbutton mv15" @click="closeRight()">
        <font-awesome-icon
          :icon="['fal', 'arrow-alt-left']"
          size="lg"
        ></font-awesome-icon>
      </div>
      <div v-if="mode.view">
        <div class="iconbutton mv15" @click="switchMode('edit')">
          <font-awesome-icon
            :icon="['fal', 'edit']"
            size="lg"
          ></font-awesome-icon>
        </div>
      </div>

      <div v-if="mode.edit">
        <div class="iconbutton mv15" @click="switchMode('view')">
          <font-awesome-icon
            :icon="['fal', 'eye']"
            size="lg"
          ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="taskSave()">
          <font-awesome-icon
            :icon="['fal', 'save']"
            size="lg"
          ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="taskDelete()">
          <font-awesome-icon
            :icon="['fal', 'trash']"
            size="lg"
          ></font-awesome-icon>
        </div>
      </div>
    </div>
    <!-- navi: end -->
    <div class="contentright-body">
      <div class="contentright-body-header"></div>
      <div class="contentright-body-body scrolly m9">
        <div v-if="mode.view">
          <!-- basic: start -->
          <h3 class="txth4">{{ intask.tasktext }}</h3>
          <div class="txtsm mt11">
            Goal/Project:
            <span class="txtbold" v-if="tsk.goalid === ''"
              >Not assigned to a goal</span
            >
            <span class="txtbold" v-if="tsk.goalid !== ''">{{
              tsk.goalname
            }}</span>
            Client:
            <span class="txtbold" v-if="tsk.clientid === ''">Private</span>
            <span class="txtbold" v-if="tsk.clientid !== ''">{{
              tsk.client
            }}</span>
          </div>
          <div>
            <font-awesome-icon
              :icon="['fal', tsk.useicon]"
              size="sm"
            ></font-awesome-icon>
            {{ tsk.workflow }}
          </div>
          <div>{{ tsk.taskdescription }}</div>
          <div class="sp20"></div>

          <!-- Client:start  -->
          <!-- <div class="isFlex mb11">
            <div class="roundedleft lgreybg p5" style="width: 30%">Client</div>
            <div class="roundedright vlgreybg p5 isFlexGrow">
              <span v-if="tsk.clientid === ''">Private</span>
              <span v-if="tsk.clientid !== ''">{{tsk.client}}</span>
            </div>
          </div> -->
          <!-- Client:start  -->

          <!-- basic:end -->

          <!-- steps: start -->
          <section class="tsk lgreybg">
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth5">Steps</div>
              <div>
                <div class="iconbutton" @click="addStep('add', {}, -1)">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              class="tsk vlgreybg mt11"
              v-if="tsk.steps && tsk.steps.length === 0"
            >
              No steps added
            </div>
            <draggable
              :list="tsk.steps"
              handle=".handle"
              @change="stepsReOrder($event)"
            >
              <div v-for="(st, index) in tsk.steps" :key="st._id">
                <div class="tsk vlgreybg mt7">
                  <div class="isFlex isFlexAlign">
                    <div class="isFlexGrow">
                      <div>{{ st.steptext }}</div>
                      <div class="txtsm">
                        {{ st.workflow }} | {{ st.firstname }}
                      </div>
                    </div>
                    <div class="isFlex">
                      <div class="iconbutton" @click="deleteStep(st, index)">
                        <font-awesome-icon
                          :icon="['fal', 'trash']"
                          size="sm"
                        ></font-awesome-icon>
                      </div>
                      <div
                        class="iconbutton"
                        @click="addStep('edit', st, index)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'edit']"
                          size="sm"
                        ></font-awesome-icon>
                      </div>
                      <div class="iconbutton handle">
                        <font-awesome-icon
                          :icon="['fal', 'arrows']"
                          size="sm"
                        ></font-awesome-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </section>
          <!-- steps: end -->

          <div class="sp20"></div>

          <!-- notes:start -->
          <section class="tsk lgreybg">
            <!-- notes: start  -->
            <div class="isFlexGrow txth5">Notes</div>
            <div>
              <div class="isFlex tsk lgreybg p5 mb11">
                <div class="contentleft-header-searchbox">
                  <b-input
                    placeholder="Search"
                    type="search"
                    icon="search"
                    rounded
                    v-model="notesSearchField"
                    @input="notesSearch()"
                  ></b-input>
                </div>
                <div class="iconbutton mt5" @click="noteAdd('new', {}, -1)">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>

              <div
                class="tsk mb11 vlgreybg isFlex"
                v-for="(nt, index) in nts"
                :key="nt._id"
              >
                <div class="isFlexGrow">
                  <div class="txtsm mb3 isPointer">
                    {{ nt.subname }} |
                    {{ nt.notedt | displaydateTS("HH:mm ddd Do MMM YY") }}
                  </div>
                  <div
                    class="isPointer"
                    v-if="!nt.expand"
                    @click="noteExpand(nt, index)"
                  >
                    {{ nt.notepre }}
                  </div>
                  <div
                    class="txt"
                    v-if="nt.expand"
                    @click="noteExpand(nt, index)"
                    v-html="nt.notehtml"
                  ></div>
                </div>
                <div class="isFlex">
                  <div class="iconbutton" @click="noteAdd('edit', nt, index)">
                    <font-awesome-icon
                      :icon="['fal', 'edit']"
                      size="sm"
                    ></font-awesome-icon>
                  </div>
                  <div class="iconbutton" @click="noteDelete(nt, index)">
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      size="sm"
                    ></font-awesome-icon>
                  </div>
                </div>
              </div>
              <div
                v-if="nts.length >= notesLimit"
                class="isFlex isFlexJustify mt11"
              >
                <b-button @click="notesLoadNext()">Load More</b-button>
              </div>
              <div class="tsk vlgreybg mv7" v-if="nts.length === 0">
                No notes found.
              </div>
            </div>
            <!-- notes: end  -->
          </section>
          <!-- notes:end -->

          <!-- grind:start -->
          <div class="sp20"></div>
          <section class="mt11">
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth4">Grind</div>
            </div>
            <!-- duration once:start -->
            <div class="isFlex mb11">
              <div class="roundedleft lgreybg p5" style="width: 30%">Type</div>
              <div class="roundedright vlgreybg p5 isFlexGrow">
                {{ tsk.durantiontype }}
              </div>
            </div>
            <!-- duration once:end -->
            <!-- est:start -->
            <div class="isFlex mb11">
              <div class="roundedleft lgreybg p5" style="width: 30%">Est</div>
              <div class="roundedright vlgreybg p5 isFlexGrow">
                <strong>{{ tsk.estblocks }} </strong>
                ( <span>{{ tsk.esthours }} </span>h
                <span>{{ tsk.estminutes }} </span>)
              </div>
            </div>
            <!-- est:start -->
            <!-- duration daily:start -->
            <section v-if="tsk.durantiontype === 'daily'">
              <!-- daily run:start -->
              <div class="isFlex mb11">
                <div class="roundedleft lgreybg p5" style="width: 30%">
                  Daily run
                </div>
                <div class="roundedright vlgreybg p5 isFlexGrow">
                  <span v-if="tsk.durantiondaily === 'tillcompleted'"
                    >Untill Completed</span
                  >
                  <span v-if="tsk.durantiondaily === 'timeline'"
                    >Defined Timeline</span
                  >
                </div>
              </div>
              <!-- daily run:start -->
              <!-- monday:start -->
              <div class="isFlex mb11" v-if="tsk.monday">
                <div class="roundedleft lgreybg p5" style="width: 30%">
                  Monday
                </div>
                <div class="roundedright vlgreybg p5 isFlexGrow">
                  <strong>{{ tsk.mondaysettings.hours }} </strong>Hours
                  <strong>{{ tsk.mondaysettings.minutes }} </strong>Minutes
                </div>
              </div>
              <!-- monday:start -->
              <!-- tuesday:start -->
              <div class="isFlex mb11" v-if="tsk.tuesday">
                <div class="roundedleft lgreybg p5" style="width: 30%">
                  Tuesday
                </div>
                <div class="roundedright vlgreybg p5 isFlexGrow">
                  <strong>{{ tsk.tuesdaysettings.hours }} </strong>Hours
                  <strong>{{ tsk.tuesdaysettings.minutes }} </strong>Minutes
                </div>
              </div>
              <!-- tuesday:start -->
              <!-- wednesday:start -->
              <div class="isFlex mb11" v-if="tsk.wednesday">
                <div class="roundedleft lgreybg p5" style="width: 30%">
                  Wednesday
                </div>
                <div class="roundedright vlgreybg p5 isFlexGrow">
                  <strong>{{ tsk.wednesdaysettings.hours }} </strong>Hours
                  <strong>{{ tsk.wednesdaysettings.minutes }} </strong>Minutes
                </div>
              </div>
              <!-- wednesday:start -->
              <!-- thursday:start -->
              <div class="isFlex mb11" v-if="tsk.thursday">
                <div class="roundedleft lgreybg p5" style="width: 30%">
                  Thursday
                </div>
                <div class="roundedright vlgreybg p5 isFlexGrow">
                  <strong>{{ tsk.thursdaysettings.hours }} </strong>Hours
                  <strong>{{ tsk.thursdaysettings.minutes }} </strong>Minutes
                </div>
              </div>
              <!-- thursday:start -->
              <!-- friday:start -->
              <div class="isFlex mb11" v-if="tsk.friday">
                <div class="roundedleft lgreybg p5" style="width: 30%">
                  Friday
                </div>
                <div class="roundedright vlgreybg p5 isFlexGrow">
                  <strong>{{ tsk.fridaysettings.hours }} </strong>Hours
                  <strong>{{ tsk.fridaysettings.minutes }} </strong>Minutes
                </div>
              </div>
              <!-- friday:start -->
              <!-- saturday:start -->
              <div class="isFlex mb11" v-if="tsk.saturday">
                <div class="roundedleft lgreybg p5" style="width: 30%">
                  Saturday
                </div>
                <div class="roundedright vlgreybg p5 isFlexGrow">
                  <strong>{{ tsk.saturdaysettings.hours }} </strong>Hours
                  <strong>{{ tsk.saturdaysettings.minutes }} </strong>Minutes
                </div>
              </div>
              <!-- saturday:start -->
              <!-- sunday:start -->
              <div class="isFlex mb11" v-if="tsk.sunday">
                <div class="roundedleft lgreybg p5" style="width: 30%">
                  Sunday
                </div>
                <div class="roundedright vlgreybg p5 isFlexGrow">
                  <strong>{{ tsk.sundaysettings.hours }} </strong>Hours
                  <strong>{{ tsk.sundaysettings.minutes }} </strong>Minutes
                </div>
              </div>
              <!-- sunday:start -->
            </section>
            <!-- duration daily:end -->
          </section>
          <!-- grind:end -->

          <!-- availableto: start -->
          <div class="isFlex isFlexAlign">
            <div class="isFlexGrow txth4">Availble to team(s)</div>
          </div>
          <div
            class="dtable-row tsk vlgreybg mv7"
            v-for="av in tsk.availableto"
            :key="av._id"
          >
            <div class="dtable-row-middle">
              <strong>{{ av.firstname }}</strong>
            </div>
          </div>
          <div
            class="tsk vlgreybg mv7"
            v-if="tsk.availableto && tsk.availableto.length === 0"
          >
            Only available to me
          </div>
          <!-- availableto: ends -->

          <!-- Assignedto: start -->
          <section v-if="tsk.availableto && tsk.availableto.length !== 0">
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth4">Assigned to</div>
            </div>
            <div
              class="dtable-row tsk vlgreybg mv7"
              v-for="av in tsk.assignedto"
              :key="av._id"
            >
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong> <br />{{ av.role }}
              </div>
            </div>
            <div
              class="tsk vlgreybg mv7"
              v-if="tsk.assignedto && tsk.assignedto.length === 0"
            >
              Not assigned
            </div>
          </section>
          <!-- Assignedto: ends -->
        </div>

        <div v-if="mode.edit">
          <!-- edit:basic:start -->
          <section>
            <b-field label="Task">
              <b-input
                v-model="tsk.tasktext"
                type="textarea"
                rows="2"
              ></b-input>
            </b-field>
            <b-field label="Task type">
              <b-select
                placeholder="Select type"
                v-model="tsk.taskcat"
                expanded
              >
                <option value="Work">Work</option>
                <option value="Strategic">Strategic</option>
                <option value="Sales">Sales</option>
                <option value="Marketing">Marketing</option>
                <option value="Self Improvement">Self Improvement</option>
                <option value="Breakout">Breakout</option>
              </b-select>
            </b-field>
            <b-field label="Additional description">
              <b-input
                v-model="tsk.taskdescription"
                type="textarea"
                rows="2"
              ></b-input>
            </b-field>
            <b-field label="Workflow" class="mt7">
              <b-select expanded v-model="tsk.workflow">
                <option
                  v-for="wf in workflow"
                  :value="wf.value"
                  :key="wf.value"
                >
                  {{ wf.label }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Client">
              <div class="tsk inputbr p4 isFlex">
                <div class="isFlexGrow">
                  <span v-if="tsk.clientid === ''">No client selected</span>
                  <span v-else>{{ tsk.client }}</span>
                </div>
                <div class="iconbutton" @click="selectClient()">
                  <font-awesome-icon
                    :icon="['fal', 'id-card']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </b-field>
            <b-field label="Assigned to goal/project" class="mt7">
              <b-select
                placeholder="Select Goal"
                expanded
                v-model="goalSelected"
                @input="setGoal()"
              >
                <optgroup
                  :label="goal.goaltitle"
                  v-for="goal in GoalList"
                  :key="goal._id"
                >
                  <option :value="goal.val">{{ goal.goaltitle }}</option>
                  <option
                    v-for="gc in goal.children"
                    :value="gc.val"
                    :key="gc._id"
                  >
                    {{ gc.goaltitle }}
                  </option>
                </optgroup>
              </b-select>
            </b-field>
          </section>
          <!-- edit:basic:end -->

          <!-- team: start -->
          <section>
            <!-- team select: start -->

            <!-- availableto: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Availble to team(s)</div>
              <div>
                <div class="iconbutton" @click="selectPerson()">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              class="dtable-row tsk lgreybg mv7"
              v-for="(av, index) in tsk.availableto"
              :key="av._id"
            >
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong>
              </div>
              <div class="dtable-row-right">
                <div class="iconbutton" @click="availabletoDelete(index)">
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div class="tsk lgreybg mv7" v-if="tsk.availableto.length === 0">
              Only available to me
            </div>
            <!-- availableto: ends -->

            <!-- team select: end -->
            <!-- assigned: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Assigned to</div>
              <div>
                <div class="iconbutton" @click="assignOpen('new', {}, -1)">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              class="dtable-row tsk lgreybg mv7"
              v-for="(av, index) in tsk.assignedto"
              :key="av._id"
            >
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong> <br />{{ av.role }}
              </div>
              <div class="dtable-row-right">
                <div class="iconbutton" @click="assignOpen('edit', av, index)">
                  <font-awesome-icon
                    :icon="['fal', 'edit']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
                <div class="iconbutton" @click="assignedDelete(index)">
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              class="tsk lgreybg mv7"
              v-if="tsk.assignedto && tsk.assignedto.length === 0"
            >
              Not assigned to any team member.
            </div>
            <!-- assigned: ends -->
          </section>
          <!-- team: end -->

          <!-- edit:grind:start -->
          <section>
            <div class="isFlexGrow txth3">Grind</div>
            <b-field label="Type">
              <b-select
                placeholder="Select"
                expanded
                v-model="tsk.durantiontype"
              >
                <option value="once">Once</option>
                <option value="daily">Daily</option>
              </b-select>
            </b-field>
            <b-field label="Due date" v-if="tsk.durantiontype === 'once'">
              <b-datepicker
                v-model="dtEnd"
                placeholder="Click to select..."
                icon="calendar"
              >
              </b-datepicker>
            </b-field>
            <b-field label="Due time" v-if="tsk.durantiontype === 'once'">
              <b-clockpicker
                v-model="dtEndTime"
                placeholder="Click to select..."
                icon="clock"
                hour-format="24"
              >
              </b-clockpicker>
            </b-field>
            <div class="mt11">
              <div class="txtbold mb7">Estimated duration of the task</div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow">Hours in an average work day</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grindhoursinday"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow">Average work days in a week</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grinddaysinweek"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow">Average work days in a month</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grinddaysinmonth"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow">Years</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grindyears"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow ">Months</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grindmonths"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow">Weeks</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grindweeks"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow">Days</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grinddays"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow">Hours</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grindhours"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="isFlexGrow">Minutes</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.grindminutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
              </div>
            </div>

            <div class="mt11" v-if="tsk.durantiontype === 'daily'">
              <div class="txtbold mb3">Daily duration</div>
              <div class="mb7">
                How many hours and minutes will be spent on this task per day?
              </div>
              <div class="isFlex inputbr isFlexAlign tsk mb5">
                <div class="p5">H</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.esthours"
                    size="is-small"
                    type="is-light"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
                <div class="p5">M</div>
                <div style="width: 100px;">
                  <b-numberinput
                    v-model="tsk.estminutes"
                    size="is-small"
                    type="is-light"
                    step="15"
                    @input="blocksCalc"
                  ></b-numberinput>
                </div>
                <div class="ml7">
                  <b-button @click="setDays"
                    ><font-awesome-icon
                      :icon="['fal', 'play']"
                      size="sm"
                    ></font-awesome-icon
                  ></b-button>
                </div>
              </div>
            </div>

            <div class="tsk lgreybg p7">
              Based on your daily time allocation of
              <strong>{{ tsk.esthours }}</strong> hours and
              <strong>{{ tsk.estminutes }}</strong> minutes, you will need
              <strong>{{ this.DaysNeeded }}</strong> days (<strong>{{
                this.BlocksNeeded
              }}</strong>
              blocks) to complete this task.
            </div>

            <section v-if="tsk.durantiontype === 'daily'">
              <b-field label="Daily run">
                <b-select
                  placeholder="Select"
                  expanded
                  v-model="tsk.durantiondaily"
                >
                  <option value="tillcompleted">Untill Completed</option>
                  <option value="timeline">Defined Timeline</option>
                </b-select>
              </b-field>
              <section>
                <b-field label="Start date">
                  <b-datepicker
                    v-model="dtStart"
                    placeholder="Click to select..."
                    icon="calendar"
                  >
                  </b-datepicker>
                </b-field>
                <b-field label="Start time">
                  <b-clockpicker
                    v-model="dtStartTime"
                    placeholder="Click to select..."
                    icon="clock"
                    hour-format="24"
                  >
                  </b-clockpicker>
                </b-field>
                <b-field label="End date">
                  <b-datepicker
                    v-model="dtEnd"
                    placeholder="Click to select..."
                    icon="calendar"
                  >
                  </b-datepicker>
                </b-field>
                <b-field label="End time">
                  <b-clockpicker
                    v-model="dtEndTime"
                    placeholder="Click to select..."
                    icon="clock"
                    hour-format="24"
                  >
                  </b-clockpicker>
                </b-field>
              </section>

              <div class="mt11">
                <div class="txtbold mb7">
                  What days of the week can you work on this task and for how
                  long every day?
                </div>
                <!-- monday -->
                <div class="txtbold mb7">Monday</div>
                <div class="isFlex inputbr isFlexAlign tsk mb5">
                  <div>
                    <b-checkbox
                      v-model="tsk.monday"
                      @input="blocksCalc"
                    ></b-checkbox>
                  </div>
                  <div class="p5">H</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.mondaysettings.hours"
                      size="is-small"
                      type="is-light"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                  <div class="p5">M</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.mondaysettings.minutes"
                      size="is-small"
                      type="is-light"
                      step="15"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                </div>
                <!-- tuesday -->
                <div class="txtbold mb7">Tuesday</div>
                <div class="isFlex inputbr isFlexAlign tsk mb5">
                  <div>
                    <b-checkbox
                      v-model="tsk.tuesday"
                      @input="blocksCalc"
                    ></b-checkbox>
                  </div>
                  <div class="p5">H</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.tuesdaysettings.hours"
                      size="is-small"
                      type="is-light"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                  <div class="p5">M</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.tuesdaysettings.minutes"
                      size="is-small"
                      type="is-light"
                      step="15"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                </div>
                <!-- wednesday -->
                <div class="txtbold mb7">Wednesday</div>
                <div class="isFlex inputbr isFlexAlign tsk mb5">
                  <div>
                    <b-checkbox
                      v-model="tsk.wednesday"
                      @input="blocksCalc"
                    ></b-checkbox>
                  </div>
                  <div class="p5">H</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.wednesdaysettings.hours"
                      size="is-small"
                      type="is-light"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                  <div class="p5">M</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.wednesdaysettings.minutes"
                      size="is-small"
                      type="is-light"
                      step="15"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                </div>
                <!-- thursday -->
                <div class="txtbold mb7">Thursday</div>
                <div class="isFlex inputbr isFlexAlign tsk mb5">
                  <div>
                    <b-checkbox
                      v-model="tsk.thursday"
                      @input="blocksCalc"
                    ></b-checkbox>
                  </div>
                  <div class="p5">H</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.thursdaysettings.hours"
                      size="is-small"
                      type="is-light"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                  <div class="p5">M</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.thursdaysettings.minutes"
                      size="is-small"
                      type="is-light"
                      step="15"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                </div>
                <!-- friday -->
                <div class="txtbold mb7">Friday</div>
                <div class="isFlex inputbr isFlexAlign tsk mb5">
                  <div>
                    <b-checkbox
                      v-model="tsk.friday"
                      @input="blocksCalc"
                    ></b-checkbox>
                  </div>
                  <div class="p5">H</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.fridaysettings.hours"
                      size="is-small"
                      type="is-light"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                  <div class="p5">M</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.fridaysettings.minutes"
                      size="is-small"
                      type="is-light"
                      step="15"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                </div>
                <!-- saturday -->
                <div class="txtbold mb7">Saturday</div>
                <div class="isFlex inputbr isFlexAlign tsk mb5">
                  <div>
                    <b-checkbox
                      v-model="tsk.saturday"
                      @input="blocksCalc"
                    ></b-checkbox>
                  </div>
                  <div class="p5">H</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.saturdaysettings.hours"
                      size="is-small"
                      type="is-light"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                  <div class="p5">M</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.saturdaysettings.minutes"
                      size="is-small"
                      type="is-light"
                      step="15"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                </div>
                <!-- sunday -->
                <div class="txtbold mb7">Sunday</div>
                <div class="isFlex inputbr isFlexAlign tsk mb5">
                  <div>
                    <b-checkbox
                      v-model="tsk.sunday"
                      @input="blocksCalc"
                    ></b-checkbox>
                  </div>
                  <div class="p5">H</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.sundaysettings.hours"
                      size="is-small"
                      type="is-light"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                  <div class="p5">M</div>
                  <div style="width: 100px;">
                    <b-numberinput
                      v-model="tsk.sundaysettings.minutes"
                      size="is-small"
                      type="is-light"
                      step="15"
                      @input="blocksCalc"
                    ></b-numberinput>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <!-- edit:grind:end -->

          <div style="height: 100px;"></div>
        </div>
      </div>
      <div class="contentright-body-footer">
        Blocks Needed: {{ BlocksNeeded }} Blocks Allocated:
        {{ BlocksEstimated }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import debounce from "lodash/debounce";
import ObjectID from "bson-objectid";
import { EventBus } from "../event-bus.js";
import { dbFunctions } from "../mixins/dbMixins.js";
import { windowSplit2 } from "../mixins/windowSplit.js";
import ModalPeopleList from "@/components/ModalPeopleList.vue";
import ModalForm from "@/components/ModalForm.vue";
import ModalEditor from "@/components/ModalEditor.vue";
import draggable from "vuedraggable";
export default {
  mixins: [dbFunctions, windowSplit2],
  name: "Navi",
  components: {
    draggable
  },
  props: {
    intask: Object,
    buspre: "",
    bussave: "",
    busdel: "",
    busclose: "",
  },

  watch: {
    intask: function(newVal, oldVal) {
      this.tsk = newVal;
      this.switchMode("view");
      this.loadTask();
    }
  },

  data() {
    return {
      user: store.state.user,
      tsk: this.intask,
      usebuspre: this.buspre,
      usebussave: this.bussave,
      usebusdel: this.busdel,
      usebusclose: this.busclose,
      GoalList: [],
      memList: [],
      mode: {
        view: true,
        edit: false
      },
      naviview: {
        notes: false
      },
      naviedit: {
        general: true,
        desc: true,
        steps: true
      },
      dtStart: null,
      dtStartTime: null,
      dtEnd: null,
      dtEndTime: null,
      BlocksEstimated: 0,
      BlocksNeeded: 0,
      // Team
      TeamLive: localStorage.getItem("TeamLive"),
      TeamLiveName: localStorage.getItem("TeamLiveName"),
      ActiveTeam: store.state.team,
      MemberSelected: {},
      MemberWorkflow: "",

      // notes: start
      nts: [],
      notesSearchField: "",
      notesLimit: 25,
      notesSkip: 0,
      noteActive: {},
      noteMethod: "new",
      nodeId: -1,
      // notes: end

      goalSelected: { _id: "all", goaltitle: "No Goal Selected", goalcolor: "" }
    };
  },

  async created() {
    EventBus.$on("Assign_Client", data => {
      this.assignClient(data);
    });
    EventBus.$on("bus_allocated", data => {
      this.assignMember(data);
    });
    EventBus.$on("task_steps_addedit", data => {
      this.addeditStep(data);
    });
    EventBus.$on("Select_Person_Task", data => {
      this.availableTo(data);
    });
    EventBus.$on("from_note_tasks", data => {
      this.noteAddDo(data);
    });
    if (this.task.durantiontype === "daily") {
      this.taskTypeIcon = "repeat";
    }
    this.loadTask();
    this.GoalList = await this.GoalsGrouped();
    this.notesGet();
  },

  computed: {
    tskComputed() {
      return this.tsk;
    }
  },

  methods: {
    switchMode(mode) {
      this.mode.view = false;
      this.mode.edit = false;
      this.mode[mode] = true;
    },

    matchGoal(id) {
      for (const gl of this.GoalList) {
        if (gl._id === id) {
          return gl.goaltitle;
        }
      }
    },

    closeRight() {
      EventBus.$emit(this.usebusclose, this.tsk);
    },

    async loadTask() {
      if (this.intask.dataid) {
        this.tsk._id = this.intask.dataid;
      }
      const q = { _id: this.tsk._id };
      const tskin = await this.getData("tasks", q, {}, "none", "none", "searchextended");
      this.tsk = tskin[0];
      if (!this.tsk.mondaysettings) {
        this.tsk.mondaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.tsk.tuesdaysettings) {
        this.tsk.tuesdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.tsk.wednesdaysettings) {
        this.tsk.wednesdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.tsk.thursdaysettings) {
        this.tsk.thursdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.tsk.fridaysettings) {
        this.tsk.fridaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.tsk.saturdaysettings) {
        this.tsk.saturdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.tsk.sundaysettings) {
        this.tsk.sundaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.tsk.grindhoursinday) {
        this.tsk.grindhoursinday = 6;
      }
      if (!this.tsk.grinddaysinmonth) {
        this.tsk.grinddaysinmonth = 21;
      }
      if (!this.tsk.grinddaysinweek) {
        this.tsk.grinddaysinweek = 5;
      }
      if (!this.tsk.grindweeksinyear) {
        this.tsk.grindweeksinyear = 52;
      }

      // dates
      if (this.tsk.taskstartdate !== "") {
        this.dtStart = new Date(this.tsk.taskstartdate);
      }
      if (this.tsk.taskstarttime !== "") {
        this.dtStartTime = new Date(this.tsk.taskstarttimets);
      }
      if (this.tsk.tasktilldate !== "") {
        this.dtEnd = new Date(this.tsk.tasktilldate);
      }
      if (this.tsk.tasktilltime !== "") {
        this.dtEndTime = new Date(this.tsk.tasktilltimets);
      }

      // goal
      this.goalSelected = {
        _id: this.tsk.goalid,
        goaltitle: this.tsk.goalname,
        goalcolor: this.tsk.goalcolor
      };
      this.blocksCalc();
    },

    selectClient() {
      const that = this;
      this.modalA = this.$buefy.modal.open({
        parent: this,
        component: ModalPeopleList,
        hasModalCard: true,
        props: {
          showOpen: false,
          showSelect: true,
          showCheckBox: false,
          hideHeader: true,
          hideRefresh: true,
          hideFilter: true,
          hideAdd: true,
          hideFooter: true,
          listPersonType: "",
          listClients: false,
          listSuppliers: false,
          listProspects: false,
          listContacts: false,
          listFriends: false,
          callBus: "Assign_Client",
          prsn: that.prsn
        }
      });
    },

    assignClient(data) {
      let usename = data.firstname;
      if (data.persontype === "Person") {
        usename = `${data.firstname} ${data.lastname}`;
      }
      this.tsk.clientid = data._id;
      this.tsk.client = usename;
    },

    // team:start
    async assignOpen(mode, row, index) {
      // set teams and members: start
      const TeamList = await this.getTeams();
      this.memList = [];

      if (this.tsk.availableto.length !== 0) {
        for (const a of this.tsk.availableto) {
          for (const t of TeamList) {
            if (a._id === t._id) {
              for (const m of t.inteam) {
                this.memList.push({
                  value: m.userid,
                  label: `${m.firstname} (${t.firstname})`
                });
              }
            }
          }
        }
      } else {
        this.memList.push({ value: this.user.sub, label: this.user.subname });
      }
      // set teams and members: start
      const fields = [
        {
          type: "selectobj",
          field: "teamMember",
          label: "Team Member",
          value: "",
          options: this.memList,
          show: true
        },
        {
          type: "select",
          field: "peopleWorkflow",
          label: "Role",
          value: "Doer",
          options: this.peopleworkflow,
          show: true
        }
        // { type: 'select', field: 'workflow', label: 'Workflow', value: 'New', options: this.workflow, show: true },
      ];
      if (mode === "edit") {
        const memval = { value: row._id, label: row.firstname };
        fields[0].value = memval;
        fields[1].value = row.role;
        fields[2].value = row.workflow;
      } else {
        const memval = { value: this.user.sub, label: this.user.subname };
        fields[0].value = memval;
      }
      this.modalAlloc = this.$buefy.modal.open({
        parent: this,
        component: ModalForm,
        hasModalCard: true,
        props: {
          title: "Assign a team member",
          fields,
          callBus: "bus_allocated",
          busData: {
            index,
            mode
          }
        }
      });
    },

    assignMember(data) {
      let member = "";
      let membername = "";
      let role = "";
      let workflow = "";
      for (const m of data.fields) {
        if (m.field === "teamMember") {
          if (m.value === "") {
            this.$buefy.snackbar.open({
              message: "Please select a team member",
              position: "is-top"
            });
            return;
          }
          member = m.value.value;
          membername = m.value.label;
        }
        if (m.field === "peopleWorkflow") {
          if (m.value === "") {
            this.$buefy.snackbar.open({
              message: "Please assign a role to this person",
              position: "is-top"
            });
            return;
          }
          role = m.value;
        }
        // if(m.field === 'workflow') {
        //   workflow = m.value;
        // }
      }
      if (data.mode === "edit") {
        this.tsk.assignedto[data.index]._id = member;
        this.tsk.assignedto[data.index].firstname = membername;
        this.tsk.assignedto[data.index].role = role;
        // this.tsk.assignedto[data.index].workflow = workflow;
        this.taskSave();
        this.modalAlloc.close();
        return;
      }
      const newAss = {
        _id: member,
        firstname: membername,
        role,
        workflow: workflow,
        completed: false
      };
      this.tsk.assignedto.push(newAss);
      this.taskSave();
      this.modalAlloc.close();
    },

    assignedDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this assignee? This action cannot be undone.",
        confirmText: "Delete person",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.tsk.assignedto.splice(index, 1);
          this.$buefy.toast.open("Assignee Removed!");
          this.taskSave();
        }
      });
    },
    // team:end

    setDays() {
      this.tsk.mondaysettings = {
        hours: this.tsk.esthours,
        minutes: this.tsk.estminutes
      };
      this.tsk.tuesdaysettings = {
        hours: this.tsk.esthours,
        minutes: this.tsk.estminutes
      };
      this.tsk.wednesdaysettings = {
        hours: this.tsk.esthours,
        minutes: this.tsk.estminutes
      };
      this.tsk.thursdaysettings = {
        hours: this.tsk.esthours,
        minutes: this.tsk.estminutes
      };
      this.tsk.fridaysettings = {
        hours: this.tsk.esthours,
        minutes: this.tsk.estminutes
      };
      this.tsk.saturdaysettings = {
        hours: this.tsk.esthours,
        minutes: this.tsk.estminutes
      };
      this.tsk.sundaysettings = {
        hours: this.tsk.esthours,
        minutes: this.tsk.estminutes
      };
      this.blocksCalc();
    },

    blocksCalc() {
      this.BlocksEstimated = 0;
      this.BlocksNeeded = 0;
      this.DaysNeeded = 0;
      let hasdays = false;
      let mondayblocks = 0;
      if (this.tsk.monday) {
        mondayblocks +=
          this.tsk.mondaysettings.hours * 4 +
          Math.round(this.tsk.mondaysettings.minutes / 15);
        hasdays = true;
      }
      let tuesdayblocks = 0;
      if (this.tsk.tuesday) {
        tuesdayblocks +=
          this.tsk.tuesdaysettings.hours * 4 +
          Math.round(this.tsk.tuesdaysettings.minutes / 15);
        hasdays = true;
      }
      let wednesdayblocks = 0;
      if (this.tsk.wednesday) {
        wednesdayblocks +=
          this.tsk.wednesdaysettings.hours * 4 +
          Math.round(this.tsk.wednesdaysettings.minutes / 15);
        hasdays = true;
      }
      let thursdayblocks = 0;
      if (this.tsk.thursday) {
        thursdayblocks +=
          this.tsk.thursdaysettings.hours * 4 +
          Math.round(this.tsk.thursdaysettings.minutes / 15);
        hasdays = true;
      }
      let fridayblocks = 0;
      if (this.tsk.friday) {
        fridayblocks +=
          this.tsk.fridaysettings.hours * 4 +
          Math.round(this.tsk.fridaysettings.minutes / 15);
        hasdays = true;
      }
      let saturdayblocks = 0;
      if (this.tsk.saturday) {
        saturdayblocks +=
          this.tsk.saturdaysettings.hours * 4 +
          Math.round(this.tsk.saturdaysettings.minutes / 15);
        hasdays = true;
      }
      let sundayblocks = 0;
      if (this.tsk.sunday) {
        sundayblocks +=
          this.tsk.sundaysettings.hours * 4 +
          Math.round(this.tsk.sundaysettings.minutes / 15);
        hasdays = true;
      }
      // this.BlocksEstimated += (mondayblocks + tuesdayblocks + wednesdayblocks + thursdayblocks + fridayblocks + saturdayblocks + sundayblocks);
      // this.BlocksNeeded += this.timeDifference()
      let useDTR = 0;
      let dayscount = 1;
      const daycounts = {
        monday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        tuesday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        wednesday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        thursday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        friday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        saturday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        sunday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        }
      };
      if (this.dtStart !== null && this.dtEnd !== null) {
        useDTR = 3;
        dayscount = this.timeDifference(this.dtEnd, this.dtStart);
        for (let index = 0; index < dayscount.days; index++) {
          const newdate = this.addDays(this.dtStart, index);
          if (this.tsk[newdate.daytext]) {
            daycounts[newdate.daytext].days += 1;
            daycounts[newdate.daytext].hours += this.tsk[
              newdate.daytext + "settings"
            ].hours;
            daycounts[newdate.daytext].minutes += this.tsk[
              newdate.daytext + "settings"
            ].minutes;
          }
        }
      }
      const daysofweek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ];
      for (const d of daysofweek) {
        const ind = daycounts[d];
        ind.totalminutes = ind.hours * 60 + ind.minutes;
        ind.blocks = ind.totalminutes / 15;
        this.BlocksEstimated += ind.blocks;
      }
      const onday = this.tsk.esthours * 60 + this.tsk.estminutes;
      let totalminutes = 0;
      if (this.tsk.grindyears !== 0) {
        totalminutes +=
          this.tsk.grindyears *
          (this.tsk.grinddaysinmonth * 52 * this.tsk.grindhoursinday * 60);
      }
      if (this.tsk.grindmonths !== 0) {
        totalminutes +=
          this.tsk.grindmonths *
          (this.tsk.grinddaysinmonth * this.tsk.grindhoursinday * 60);
      }
      if (this.tsk.grindweeks !== 0) {
        totalminutes +=
          this.tsk.grindweeks *
          (this.tsk.grinddaysinweek * this.tsk.grindhoursinday * 60);
      }
      if (this.tsk.grinddays !== 0) {
        totalminutes += this.tsk.grinddays * (this.tsk.grindhoursinday * 60);
      }
      if (this.tsk.grindhours !== 0) {
        totalminutes += this.tsk.grindhours * 60;
      }
      if (this.tsk.grindminutes !== 0) {
        totalminutes += this.tsk.grindminutes;
      }

      const totalseconds = totalminutes * 60;
      const displaydatesin = this.secondsToHours(totalseconds);
      if (onday === 0) {
        this.DaysNeeded = totalminutes / (this.tsk.grindhoursinday * 60);
      } else {
        this.DaysNeeded = totalminutes / onday;
      }
      this.BlocksNeeded = Math.round(totalminutes / 15);
      this.tsk.esthours = displaydatesin.hours;
      this.tsk.estminutes = displaydatesin.minutes;
      this.tsk.estblocks = this.BlocksNeeded;

      // this.BlocksNeeded += (blocksYears + blocksMonths + blocksWeeks + blocksDays + blocksHours + blocksMinutes );
      // this.DaysNeeded += (grindYears + grindMonths + grindWeeks + grindDays + hourMinutes + grindMinutes );
    },

    setGoal() {
      this.tsk.goalid = this.goalSelected._id;
      this.tsk.goalname = this.goalSelected.goaltitle;
      this.tsk.goalcolor = this.goalSelected.goalcolor;
    },

    async taskSave() {
      // start date
      if (this.dtStart !== null) {
        const inst = this.getDateFull(this.dtStart, "none", "none", 0);
        this.tsk.taskstartdate = inst.db;
        this.tsk.taskstartdatets = inst.tsm;
      } else {
        this.tsk.taskstartdate = "";
        this.tsk.taskstartdatets = 0;
      }
      if (this.dtStartTime !== null) {
        if (this.dtStart === null) {
          this.$buefy.snackbar.open({
            message: "Please select a start date",
            position: "is-top"
          });
          return;
        }
        const insts = this.getDateFull(this.dtStartTime, "none", "none", 0);
        var starttime = new Date(this.dtStart);
        starttime.setHours(insts.hoursingle, insts.minutesingle, 0, 0);
        this.tsk.taskstarttime = `${insts.hoursingle}:${insts.minutesingle}`;
        this.tsk.taskstarttimets = starttime.getTime();
      } else {
        this.tsk.taskstarttime = "";
        this.tsk.taskstarttimets = 0;
      }
      // end date
      if (this.dtEnd !== null) {
        const inst = this.getDateFull(this.dtEnd, "none", "none", 0);
        this.tsk.tasktilldate = inst.db;
        this.tsk.tasktilldatets = inst.tsm;
      } else {
        this.tsk.tasktilldate = "";
        this.tsk.tasktilldatets = 0;
      }
      if (this.dtEndTime !== null) {
        if (this.dtEnd === null) {
          this.$buefy.snackbar.open({
            message: "Please select an end date",
            position: "is-top"
          });
          return;
        }
        const insts = this.getDateFull(this.dtEndTime, "none", "none", 0);
        var starttime = new Date(this.dtEnd);
        starttime.setHours(insts.hoursingle, insts.minutesingle, 0, 0);
        this.tsk.tasktilltime = `${insts.hoursingle}:${insts.minutesingle}`;
        this.tsk.tasktilltimets = starttime.getTime();
      } else {
        this.tsk.tasktilltime = "";
        this.tsk.tasktilltimets = 0;
      }

      // fix goal
      // if(this.tsk.goalid !== '') {
      //   for(const g of this.GoalList) {
      //     if(g._id === this.tsk.goalid) {
      //       this.tsk.goalname = g.goaltitle;
      //     }
      //   }
      // }
      const updt = await this.updata(
        "tasks",
        this.tsk,
        "none",
        this.usebussave,
        {},
        false
      );
      EventBus.$emit("task_saved", this.tsk);
      this.$buefy.toast.open("Task saved!");
    },

    taskDelete() {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this task?",
        confirmText: "Delete Task",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.$buefy.toast.open("Task deleted!");
          this.tsk.deleted = true;
          this.updata("task", this.tsk, "none", this.usebusdel, {}, false);
        }
      });
    },

    // availableto:start
    selectPerson(mode, row, useIndex) {
      const that = this;
      this.modalA = this.$buefy.modal.open({
        parent: this,
        component: ModalPeopleList,
        hasModalCard: true,
        props: {
          showOpen: false,
          showSelect: true,
          showCheckBox: false,
          hideHeader: true,
          hideRefresh: true,
          hideFilter: true,
          hideAdd: true,
          hideFooter: true,
          listPersonType: "Team",
          listClients: false,
          listSuppliers: false,
          listProspects: false,
          listContacts: false,
          listFriends: false,
          callBus: "Select_Person_Task",
          tsk: that.tsk
        }
      });
    },

    availableTo(row) {
      const newrow = { _id: row._id, firstname: row.firstname };
      this.tsk.availableto.push(newrow);
      this.taskSave();
      this.modalA.close();
    },

    availabletoDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>remove access</b> this team? This action cannot be undone.",
        confirmText: "Remove Team",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.tsk.availableto.splice(index, 1);
          this.$buefy.toast.open("Team Removed!");
          this.taskSave();
        }
      });
    },
    // availableto:end

    // steps: start

    async addStep(mode, row, index) {
      // set teams and members: start
      const TeamList = await this.getTeams();
      this.memList = [];

      if (this.tsk.availableto.length !== 0) {
        for (const a of this.tsk.availableto) {
          for (const t of TeamList) {
            if (a._id === t._id) {
              for (const m of t.inteam) {
                this.memList.push({
                  value: m.userid,
                  label: `${m.firstname} (${t.firstname})`
                });
              }
            }
          }
        }
      } else {
        this.memList.push({ value: this.user.sub, label: this.user.subname });
      }
      // set teams and members: start
      const fields = [
        {
          type: "input",
          subtype: "textarea",
          field: "steptext",
          label: "Step to be taken",
          value: "",
          placeholder: "eg. ACME Trade, AQUA CMS",
          required: "true",
          show: true
        },
        {
          type: "select",
          field: "workflow",
          label: "Workflow",
          value: "New",
          options: this.workflow,
          show: true
        },
        {
          type: "selectobj",
          field: "teamMember",
          label: "Assigned to...",
          value: "",
          options: this.memList,
          show: true
        }
        // { type: 'select', field: 'role', label: 'Role', value: 'Doer', options: this.peopleworkflow, show: true },
      ];

      if (mode === "edit") {
        const memval = { value: row.userid, label: row.firstname };
        fields[0].value = row.steptext;
        fields[1].value = row.workflow;
        fields[2].value = memval;
        // fields[3].value = row.role;
      } else {
        const memval = { value: this.user.sub, label: this.user.subname };
        fields[2].value = memval;
      }
      this.stepModal = this.$buefy.modal.open({
        parent: this,
        component: ModalForm,
        hasModalCard: true,
        props: {
          fields,
          callBus: "task_steps_addedit",
          busData: {
            index,
            mode
          }
        }
      });
    },

    addeditStep(data) {
      let steptext = "";
      let member = "";
      let membername = "";
      let role = "";
      let workflow = "";
      for (const m of data.fields) {
        if (m.field === "teamMember") {
          if (m.value === "") {
            this.$buefy.snackbar.open({
              message: "Please select a team member",
              position: "is-top"
            });
            return;
          }
          member = m.value.value;
          membername = m.value.label;
        }
        // if(m.field === 'role') {
        //   role = m.value;
        // }
        if (m.field === "workflow") {
          workflow = m.value;
        }
        if (m.field === "steptext") {
          steptext = m.value;
        }
      }
      if (data.mode === "edit") {
        this.tsk.steps[data.index].userid = member;
        this.tsk.steps[data.index].firstname = membername;
        // this.tsk.steps[data.index].role = role;
        this.tsk.steps[data.index].workflow = workflow;
        this.tsk.steps[data.index].steptext = steptext;
        this.taskSave();
        this.stepModal.close();
        return;
      }
      const newAss = {
        userid: member,
        firstname: membername,
        // role,
        workflow,
        steptext,
        completed: false
      };
      this.tsk.steps.push(newAss);
      this.taskSave();
      this.stepModal.close();
    },

    // step edit: start
    deleteStep(row, index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this step? This action cannot be undone.",
        confirmText: "Delete step",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.tsk.steps.splice(index, 1);
          this.$buefy.toast.open("Step Removed!");
          this.taskSave();
        }
      });
    },
    stepsReOrder(evt) {
      this.taskSave();
    },
    // step edit: end
    // steps: end

    // notes: start
    notesLoadNext() {
      this.notesSkip += this.notesLimit;
      this.notesGet();
    },

    notesReload() {
      this.notesSkip = 0;
      this.nts = [];
      this.notesGet();
    },

    notesSearch: debounce(function() {
      this.notesReload();
    }, 2000),

    async notesGet() {
      let query = { taskid: this.tsk._id };
      if (this.notesSearchField !== "") {
        query = {
          $and: [
            { taskid: this.tsk._id },
            { $text: { $search: this.notesSearchField } }
          ]
        };
      }
      const ntsin = await this.getData(
        "notes",
        query,
        { limit: this.notesLimit, skip: this.notesSkip },
        "none",
        "none",
        "searchextended"
      );
      if (ntsin.length === 0) {
        this.$buefy.toast.open("No more records");
        return;
      }
      for (const nt of ntsin) {
        const ntprtxt = nt.notehtml.replace(/<[^>]*>?/gm, "");
        const pretxt = ntprtxt.substring(0, 150);
        nt.notepre = pretxt;
        nt.expand = false;
        this.nts.push(nt);
      }
      // this.nts = ntsin;
    },

    noteExpand(row, index) {
      if (this.nts[index].expand) {
        row.expand = false;
      } else {
        row.expand = true;
      }
    },

    noteAdd(method, row, id) {
      this.noteMethod = method;
      let usetext = "";
      if (method === "new") {
        this.noteActive = { ...this.note };
        const id = new ObjectID();
        const idout = id.str;
        this.noteActive._id = idout;
        this.noteActive.sub = this.user.sub;
        this.noteActive.subname = this.user.subname;
        this.noteActive.availableto = this.tsk.availableto;
        this.noteActive.taskid = this.tsk._id;
        this.noteActive.person = this.tsk.tasktext;
        const dtin = this.getDateFull(new Date(), "none, 0, 0");
        this.noteActive.notedt = dtin.tsm;
        this.noteActive.nodeId = -1;
        this.noteActive.notepre = "";
      } else {
        this.noteActive = row;
        usetext = row.notehtml;
        this.nodeId = id;
      }
      const testdate = new Date(this.noteActive.notedt);
      this.modalN = this.$buefy.modal.open({
        parent: this,
        component: ModalEditor,
        hasModalCard: true,
        props: {
          usetext,
          callBus: "from_note_tasks",
          busData: {
            id,
            method
          }
        }
      });
    },
    noteAddDo(data) {
      this.noteActive.notehtml = data.html;
      this.noteActive.notetext = data.json;
      const ntprtxt = data.html.replace(/<[^>]*>?/gm, "");
      const pretxt = ntprtxt.substring(0, 150);
      this.noteActive.notepre = pretxt;
      this.updata("notes", this.noteActive, "none", "none", {}, false);
      this.$buefy.toast.open("Note saved");
      if (this.noteMethod === "new") {
        this.nts.unshift(this.noteActive);
        this.noteMethod = "edit";
      }
    },

    async noteDelete(row, index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this note? This action cannot be undone.",
        confirmText: "Delete Note",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          row.delete = true;
          const upnte = await this.livePostPromise("upsert", "notes", row);
          this.nts.splice(index, 1);
          this.$buefy.toast.open("Note Removed!");
        }
      });
    }
    // notes: end
  }
};
</script>

<style>
.autotext {
  width: 100%;
  border: 0;
  background: none;
  font-size: 1em;
  color: 363636;
  line-height: 1.5;
  padding: 3px;
}
</style>
