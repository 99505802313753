<template>
  <div class="calcontainer">
    <div class="calcontainer-inner">
      <!-- page menu: start -->
      <div style="height: 33px; background-color: #494383; display: flex; align-items: center; padding-left: 5px; padding-right: 5px;">
        <div class="mr9" style="width: 60px;">
          <b-button size="is-small" type="is-info" icon-left="bullseye-arrow" @click="changeTaskList('goals')">Projects</b-button>
        </div>
        <div class="mr9" style="width: 60px;">
          <b-button size="is-small" type="is-info" icon-left="list-alt" @click="changeTaskList('rogue')">Tasks</b-button>
        </div>
        <div style="width: 120px;">
          <b-datepicker v-model="TodayRaw" placeholder="Click to select..." icon="calendar-day" size="is-small" icon-pack="fal" @input="setDates"></b-datepicker>
        </div>
        <div class="mr9 ml9">
          <b-button size="is-small" type="is-info" icon-left="calendar-day" @click="setDates(new Date())">Today</b-button>
        </div>
        <div class="mr9">
          <b-button size="is-small" type="is-info" icon-left="sync-alt" @click="createCal">Refresh</b-button>
        </div>
        <div class="mr9"  v-if="DayGoal !== ''">
          <b-button size="is-small" icon-left="times" @click="setGoalForDay({ _id: '', goaltitle: '' });createCal">{{DayGoalText}}</b-button>
        </div>
      </div>
      <!-- page menu: end -->
      <div class="isFlexGrow" style="overflow: hidden; position: relative;">
        <div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: auto;" id="scrollcontainer">
          <div style="width: 3310px; height: 100%; display: flex;">

            <!-- plan: start -->
            <div v-if="PlanMode" class="calcol-tasks lgreybg" v-bind:class="{ naviIsOpen: naviShow, planlist: PlanExpanded }">
              <div class="calcol-head darkgreybg">
                <div class="isFlex isFlexGrow">
                  <div class="mr9" v-if="taskList === 'rogue'">
                    <b-button size="is-small" type="is-info" icon-left="sync-alt" @click="getTasks()">Refresh</b-button>
                  </div>
                  <div class="mr9" v-if="taskList === 'rogue'">
                    <b-button size="is-small" type="is-info" icon-left="plus" @click="taskAddOpen('rogue', {})">New</b-button>
                  </div>
                </div>
                <div>
                  <div class="iconbutton mr9 bluebg7 white tsk" @click="expandPlan()">
                    <font-awesome-icon :icon="['fal', 'arrows-alt-h']" size="sm"></font-awesome-icon>
                  </div>
                </div>
              </div>
              <div class="isFlexGrow scrolly" v-if="taskList === 'goals'">
                <!-- listing:start  @end="sortList($event, GoalList, 'goals')" -->
                <draggable tag="div" :list="GoalList" handle=".handle" sort="false">
                  <!-- project:main -->
                  <section v-for="(g, index) in GoalList" :index="index" :key="index">
                    <div class="tsk vlgraybg mt11 m7" v-bind:style="g.useStyle" v-if="!g.deleted">
                      <div class="isFlex isFlexAlign">
                        <div>
                          <div class="iconbutton" @click="openChildren(g, index)" v-if="!g.expanded">
                            <font-awesome-icon :icon="['fal', 'caret-right']" size="sm"></font-awesome-icon>
                          </div>
                          <div class="iconbutton" @click="closeChildren(g, index)" v-if="g.expanded">
                            <font-awesome-icon :icon="['fal', 'caret-down']" size="sm" ></font-awesome-icon>
                          </div>
                        </div>
                        <div class="isFlexGrow">
                          {{ g.goaltitle }}
                          <!-- <br />
                          <span class="txtsm">[{{ g.goaltype }}]</span>
                          <span class="txtsm txtbold">Team:</span>
                          <span class="txtsm" v-if="g.availableto.length === 0">Only visible to me</span>
                          <span class="txtsm" v-for="a in g.availableto" :key="a._id" >&bull; {{ a.firstname }}</span>
                          <span class="txtsm txtbold" v-if="g.client !== ''">Client:</span>
                          <span class="txtsm" v-if="g.client !== ''">{{ g.client }}</span> -->
                        </div>
                        
                        <div class="isFlex">
                          <div
                            class="iconbutton" @click="openGoal(g, index, -1, 'edit', 'parent')">
                            <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                          </div>
                          <div class="iconbutton" @click="setGoalForDay(g)">
                            <font-awesome-icon :icon="['fal', 'arrow-alt-right']" size="sm"></font-awesome-icon>
                          </div>
                        </div>
                      </div>
                      <div style="margin-left: 0px;" v-if="g.loading">
                        <b-progress size="is-small" type="is-info"></b-progress>
                      </div>

                      <!-- plan: goals: tasks: start -->
                      <section v-if="g.expanded" class="mt7">
                        <div class="isFlex isFlexAlign">
                          <div class="txtbold isFlexGrow" style="margin-left: 0px;">
                            Tasks
                          </div>
                          <!-- <div
                            class="iconbutton" style="width: 29px;" @click="createTask(g)">
                            <font-awesome-icon :icon="['fal', 'plus-square']" size="sm"></font-awesome-icon>
                          </div> -->
                        </div>
                        <div class="tsk vlgreybg mb7" style="margin-left: 0px;" v-if="g.tasks && g.tasks.length === 0">
                          No tasks found
                        </div>

                        <draggable tag="div" :list="g.tasks" handle=".handle"  :sort="ingoalsort" :clone="colClone" :group="{ name: 'tasks', pull: 'clone', put: false }">
                        <!-- @end="sortListTasks($event, g.tasks, 'tasks')" -->
                          <section v-for="t in g.tasks" :key="t._id">
                            <!-- task block: start -->
                            <div class="tsk lgreybg mb7" v-bind:class="{ completed: t.completed, redborder: t.ismine }" :style="t.usestyle" v-if="!t.deleted" >
                              <div>
                                <div class="isFlex" style="font-size: 0.9em;">
                                  <!-- <div v-if="t.ismine" class="meeting mr3">Me</div> -->
                                  <div class="isFlexGrow">{{ t.tasktext }}</div>
                                  
                                </div>
                                <div class="txtsm">
                                  {{ t.taskdescription }}
                                </div>
                                <div class="isFlex isFlexAlign">
                                  <div class="isFlexGrow txtsm">
                                    <!-- <span style="font-weight: bold;">{{ t.estblock }}</span>&nbsp; -->
                                    <span>{{ t.esthours }}h </span>
                                    <span>{{ t.estminutes }}m</span>
                                    &nbsp;
                                    <!-- <span>{{ t.taskcount }}</span> -->
                                  </div>
                                  <div class="iconbutton" @click="openTask(t, index, null)">
                                    <font-awesome-icon :icon="['fal', 'eye']" size="sm"></font-awesome-icon>
                                  </div>
                                  <div class="iconbutton handle" v-if="t.taskcount === 0">
                                    <font-awesome-icon :icon="['fal', 'arrow-from-left']" size="sm"></font-awesome-icon>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                            <!-- task block: end -->
                          </section>
                        </draggable>
                      </section>
                    <!-- plan: goals: tasks: end -->
                    </div>
                  </section>
                </draggable>
                <!-- listing:end -->
              </div>

              <!-- rogue tasks: start -->
              <div class="isFlexGrow scrolly p7" v-if="taskList === 'rogue'">
                <div class="tsk blue1bg" v-if="rogueTasks.length === 0">No rogue tasks found.</div>
                <draggable tag="div" :list="rogueTasks" handle=".handle" :clone="colClone" @end="sortListTasks($event, rogueTasks, 'tasks')" :group="{ name: 'tasks', pull: 'clone', put: false }">
                  <section v-for="(t, index) in rogueTasks" :key="t._id">
                    <!-- task block: start -->
                    <div class="tsk vlgreybg mb7" v-bind:class="{ completed: t.completed }" style="margin-left: 0px;" v-if="!t.deleted">
                      <div>
                        <div class="isFlex">
                          <div class="isFlexGrow" style="font-size: 0.9em;">{{ t.tasktext }}</div>
                          <div style="padding: 2px 2px 0 5px;">
                            <b-checkbox size="is-small" v-model="t.completed" @input="updateWorkflow(t, null, true, -1)"></b-checkbox>
                          </div>
                        </div>
                        
                        <div class="txtsm">
                          <span v-if="t.taskstartdate !== ''">
                            <font-awesome-icon :icon="['fal', 'hourglass-start']" size="sm"></font-awesome-icon>
                            {{ t.taskstartdate }}
                          </span>
                          <span v-if="t.tasktilldate !== ''">
                            <font-awesome-icon :icon="['fal', 'hourglass-end']" size="sm"></font-awesome-icon>
                            {{ t.tasktilldate }}
                          </span>
                        </div>
                      </div>
                      <div class="isFlex isFlexAlign">
                        <div class="isFlexGrow txtsm">
                          <!-- <span style="font-weight: bold;">{{ t.estblocks }}</span>&nbsp; -->
                          <span>{{ t.esthours }}h </span>
                          <span>{{ t.estminutes }} </span>
                          &nbsp;
                          <!-- <font-awesome-icon :icon="['fal', t.useicon]" size="sm"></font-awesome-icon> -->
                          <span>{{ t.workflow }}</span>
                        </div>
                        <div class="iconbutton" @click="deleteTask({}, t, index)">
                          <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                        </div>
                        <div class="iconbutton" style="width: 29px;" @click="openTask(t, index, null, true)">
                          <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                        </div>
                        <div class="iconbutton handle">
                          <font-awesome-icon :icon="['fal', 'arrows']" size="sm"></font-awesome-icon>
                        </div>
                      </div>
                    </div>
                    <!-- task block: end -->
                  </section>
                </draggable>
              </div>
              <div class="p3 darkgreybg white">
                <b-checkbox v-model="ShowCompleted">
                  Show Completed
                </b-checkbox>
              </div>
            </div>
            <!-- task list: end -->

            <div v-if="PlanMode" style="width: 300px;">
              empty
            </div>

            <!-- cal col: start -->
            <div class="calcol" v-for="(c, index) in calList" :key="index" :class="{ vlgreybg: TodayTxt === c.dateDayTxt }">
              <div class="calcol-head lgreybg" v-bind:class="todayClass(c.dateDayTxt)">
                <div style="border-radius: 50%; background-color: #FFFFFF; width: 26px; text-align: center; margin: 2px; font-weight: bold; color: #1b1464;">
                  {{ c.dateDay }}
                </div>
                <div class="isFlexGrow ml3">{{ c.dateDayTxt }}</div>
                  <template v-if="!c.clonemode">
                    <b-tooltip label="Refresh Day" type="is-dark" position="is-bottom">
                    <b-button size="is-small" type="is-info" outlined icon-left="sync-alt" @click="getDay(c.dateDB, index)"></b-button>
                  </b-tooltip>
                  <b-tooltip class="ml5" label="Add a new task" type="is-dark" position="is-bottom">
                    <b-button size="is-small" type="is-info" outlined icon-left="plus" @click="taskAddOpen('day', c)"></b-button>
                  </b-tooltip>
                  <b-tooltip class="ml5" label="Create a new meeting" type="is-dark" position="is-bottom">
                    <b-button size="is-small" type="is-danger" outlined icon-left="handshake" @click="addMeeting(c, 'meeting')"></b-button>
                  </b-tooltip>
                  <b-tooltip class="ml5" label="Set a new availability slot" type="is-dark" position="is-bottom">
                    <b-button size="is-small" type="is-success" outlined icon-left="calendar-star" @click="addMeeting(c, 'availability')"></b-button>
                  </b-tooltip>
                  <b-tooltip class="ml5" label="Change to clone mode" type="is-dark" position="is-bottom">
                    <b-button size="is-small" type="is-info" outlined icon-left="clone" @click="changeToClone(c, index)"></b-button>
                  </b-tooltip>
                </template>
                <template v-if="c.clonemode">
                  <b-tooltip v-if="c.clonesource" class="ml5" label="Cancel Clone" type="is-dark" position="is-bottom">
                    <b-button size="is-small" type="is-info" outlined icon-left="times" @click="cancelClone(c, index)"></b-button>
                  </b-tooltip>
                  <b-tooltip v-if="!c.clonesource" class="ml5" label="Clone into this column" type="is-dark" position="is-bottom">
                    <b-button size="is-small" type="is-info" outlined icon-left="arrow-alt-down" @click="cloneItems(c, index)"></b-button>
                  </b-tooltip>
                </template>
              </div>

              <div style="margin-left: 0px;" v-if="c.loader">
                <b-progress size="is-small" type="is-info"></b-progress>
              </div>

              <div v-if="c.events.length === 0" class="tsk m7 whitebg">
                No tasks added. Get busy!
              </div>
              <draggable class="isFlexGrow scrolly p7" :list="c.events" group="tasks" handle=".handle" @change="colChanged($event, c, index)">
                <div v-for="(element, key) in c.events" :key="key">
                  <!-- divider block start -->
                  <div v-if="element.type === 'divider'" class="tsk bluebg5 isFlex mb7 white">
                    <div class="txtbold mr5">{{element.duration}}</div>
                    <div class="isFlexGrow">{{element.title}}</div>
                    <div class="iconbutton handle" v-if="DayGoal === ''">
                      <font-awesome-icon :icon="['fal', 'arrows']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                  <!-- divider block end -->
                  <!-- task block: start -->
                  <div class="tsk vlgreybg mb7 isFlex isFlexCol lgreyborder" v-if="!element.hide && element.type !== 'divider'" v-bind:class="{ completed: element.completed }" v-bind:style="{ marginLeft: '0px', borderLeft: `5px solid ${element.colour} !important`, minHeight: `${element.blockHeight}px`}">
                    <!-- header -->
                    <div class="isFlex">
                      <div class="isFlexGrow">
                        <div class="txtbold isPointer" v-if="element.type === 'task'" @click="openTask(element, index, c)">
                          {{ element.title }}
                        </div>

                        <div class="isPointer" v-if="element.type === 'meeting'" @click="(element.expanded) ? element.expanded=false:element.expanded=true">
                          <div class="txtbold">{{ element.hourstart }}:{{ element.minutestart }} Meeting</div>
                          {{ element.title }}
                        </div>

                        <div class="isPointer" v-if="element.type === 'availability'" @click="(element.expanded) ? element.expanded=false:element.expanded=true">
                          <div class="txtbold">{{ element.hourstart }}:{{ element.minutestart }} {{ element.slottype }} Session</div>
                          {{ element.title }}
                        </div>

                        <div class="txtsm txtitalic">
                          {{ element.parenttext }}
                        </div>
                        <div class="txtsm">
                          {{ element.description }}
                        </div>
                      </div>
                      <div v-if="element.type === 'project'" class="project">P</div>
                      <div v-if="element.type === 'task'" class="task">T</div>
                      <div v-if="element.type === 'meeting'" class="meeting">M</div>
                      <div v-if="element.type === 'availability'" class="availability">A</div>
                      <div style="padding: 2px 2px 0 5px;" v-if="element.ismine && !element.demo">
                        <b-checkbox size="is-small" v-model="element.completed" @input="updateWorkflow(element, c, true, index)"></b-checkbox>
                      </div>
                      <div style="padding: 2px 2px 0 5px;" v-if="element.demo">
                        <b-checkbox size="is-small" v-model="element.completed" @input="updateDemo(element, c, index)"></b-checkbox>
                      </div>
                    </div>
                    <!-- body -->
                    <div class="isFlexGrow">

                      <!-- meeting:start -->
                      <div v-if="(element.type === 'meeting' || element.type === 'availability') && element.expanded" style="border-top: 1px solid #EFEFEF; margin-top: 3px; padding: 5px;">
                        <div style="font-size: 0.9em; margin-bottom: 3px; font-weight: bold;">
                          Attendees
                        </div>
                        <div class="tsk lgreybg mb3 txtsm" v-if="element.people.length === 0">
                          No attendees
                        </div>
                        <div class="tsk lgreybg mb3" v-for="(p, pi) in element.people" :key="pi">
                          <div class="txtbold">
                            {{ p.firstname }} {{ p.lastname }} <span v-if="p.confirmed" class="bluetxt8">(confirmed)</span>
                          </div>
                          <div><a :href="`mailto:${p.email}`">{{p.email}}</a></div>
                          <div><a :href="`tel:${p.email}`">{{p.mobile}}</a></div>
                        </div>
                        <div class="mt11 mb5 txtbold">
                          {{element.where}}
                        </div>
                        <div class="tsk lgreybg mb3 txtsm" v-if="element.where === 'Away'">
                          <div class="txtitalic">Travel time is: {{ element.travelhours }}h {{ element.travelminutes }}</div>
                          <div>{{ element.address }}</div>
                        </div>
                        <div class="tsk lgreybg mb3" v-if="element.where === 'Online External'">
                          <div class="txtmd txtbold">Url</div>
                          <div style="overflow: hidden;"><a :href="element.confurl" target="_blank">{{element.confurl}}</a></div>
                          <div class="txtmd txtbold">ID/User/Room</div>
                          <div>{{element.confuser}}</div>
                          <div class="txtmd txtbold">Pin</div>
                          <div>{{element.confpin}}</div>
                        </div>
                      </div>
                      <!-- meeting:end -->

                      <!-- main: tasks -->
                      <!-- task footer -->
                    </div>

                    <!-- main footer -->
                    <div v-if="element.tasks.length !== 0" style="border-bottom: 1px solid #EFEFEF; margin: 2px 0 5px 0;"></div>
                    <div>
                      <div v-if="element.editPeople">
                        <div v-for="(p, index) in element.people" :key="p._id" :index="index">
                          <div class="isFlex tsk lgreybg mt3 isFlexSpace">
                            <div>{{ getInitials(p.username) }}</div>
                            <div class="txtsm">{{p.workflow}}</div>
                          </div>
                        </div>
                      </div>
                      <div v-if="element.type === 'task' && element.editWorkFlow && element.ismine && !element.demo">
                        <b-select placeholder="Select" size="is-small" expanded v-model="element.workflow" @input="updateWorkflow(element, c, false, index)">
                          <option v-for="w in workflow" :value="w.value" :key="w.value">
                            {{ w.label }}
                          </option>
                        </b-select>
                      </div>
                      <div v-if="element.editBlocks" class="isFlex isFlexAlign" style="padding: 7px 3px;">
                        <div class="isFlex isFlexGrow">
                          <!-- <b-numberinput controlsPosition="compact" v-model="element.newblocks" size="is-small" @input="updateTime(element)"/> -->
                          <b-numberinput controlsPosition="compact" v-model="element.hours" size="is-small" step="1" min="0" max="23"/>
                          <b-numberinput controlsPosition="compact" v-model="element.minutes" size="is-small" step="15" min="0" max="45"/>
                        </div>
                        <div>
                          <b-button @click="updateBlocks(element, c)" size="is-small">Update</b-button>
                        </div>
                      </div>
                      <!-- <div>
                        {{ element.people }}
                      </div> -->
                      <div class="isFlex">
                        <!-- mainfoot -->
                        <div class="isFlexGrow isFlex isFlexAlign pl9">
                          
                          <div @click="editBlocksExpand(element)" style="font-weight: bold; cursor: pointer;">
                            <span v-if="element.hours !== 0">{{ element.hours }}h </span>{{ element.minutes }}m&nbsp;
                          </div>
                        
                          <div v-if="element.type === 'availability'">{{element.people.length}}/{{element.attendees}}</div>
                          <div v-if="element.type === 'task' && element.people.length > 1 || element.ismine === false" class="iconbutton bluetxt10" style="width: 45px;" @click="peopleExpand(element)">
                            <font-awesome-icon :icon="['fal', 'users']" size="sm"></font-awesome-icon>&nbsp; {{element.people.length}}
                          </div>
                          <div v-if="element.type === 'task'" class="iconbutton" style="width: 29px;" @click="workflowExpand(element)">
                            <font-awesome-icon :icon="['fal', 'sparkles']" size="sm"></font-awesome-icon>
                          </div>
                          <div v-if="element.type === 'task'" class="txtsm isFlexGrow">
                            {{ element.workflow }}
                          </div>
                        </div>
                        <div v-if="element.type === 'task'" class="iconbutton bluetxt10" style="width: 29px;" @click="openTask(element, index, c)">
                          <font-awesome-icon :icon="['fal', 'eye']" size="sm"></font-awesome-icon>
                        </div>
                        <div v-if="element.type === 'meeting'" class="iconbutton" style="width: 29px;" @click="openMeeting(c, element)">
                          <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                        </div>
                        <div v-if="element.type === 'availability'" class="iconbutton" style="width: 29px;" @click="openMeeting(c, element)">
                          <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                        </div>
                        <div class="iconbutton" @click="removeColItem(c, element, index, key)" v-if="element.ismine || element.demo">
                          <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                        </div>
                        <div class="iconbutton handle" v-if="DayGoal === ''">
                          <font-awesome-icon :icon="['fal', 'arrows']" size="sm"></font-awesome-icon>
                        </div>
                        <div class="ml5 mt3"  v-if="c.clonesource">
                          <b-checkbox size="is-small" v-model="element.selected"></b-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- task block: end -->
                </div>
              </draggable>
              <!-- <div class="isFlexGrow scrolly">
                <div class="tsk m7 whitebg">No tasks added. Stop loafing!</div>
              </div> -->
              <!-- <div class="p3 bluebg2">
                <span><strong>{{ c.totalblocks }}</strong>/24 | <strong>{{ c.totalhours }}</strong>h {{ c.totalminutes }}</span>
              </div> -->
            </div>
            <!-- cal col: start -->
          </div>
        </div>
      </div>
      <!-- <div>foot</div> -->
    </div>
    <!-- popups: start -->

    <b-modal :active.sync="showTask" :width="400" scroll="clip" has-modal-card>
      <div class="card modal-card roundedtop roundedbottom">
        <div class="card-header roundedtop bluebg10">
          <div class="card-header-title white">Task</div>
        </div>
        <div class="modal-card-body">
          <div class="content">
            <b-field label="Task title">
              <b-input type="textarea" rows="1" v-model="newTask.tasktext"></b-input>
            </b-field>
            <b-field label="Description">
              <b-input type="textarea" rows="2" v-model="newTask.taskdescription"></b-input>
            </b-field>
            <div>Estimated time to complete</div>
            <div class="isFlex tsk bluebg1">
              <div style="width: 50%; padding-right: 10px;">
                <b-field label="Hours">
                  <b-numberinput size="is-small" min="0" max="23" v-model="newTask.esthours"></b-numberinput>
                </b-field>
              </div>
              <div style="width: 50%; padding-left: 10px;">
                <b-field label="Minutes">
                  <b-numberinput size="is-small" v-model="newTask.estminutes" step="15" min="0" max="45"></b-numberinput>
                </b-field>
              </div>
            </div>
            <b-field label="Assign to goal" class="mt7">
              <b-select placeholder="Select Goal" expanded v-model="goalSelected" @input="setTaskGoal()">
                <option v-for="gc in GoalList" :value="gc" :key="gc._id">{{ gc.goaltitle }}</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <footer class="card-footer roundedbottom">
          <div class="card-footer-item roundedbottomleft isPointer bluebg1 bluetxt8" @click="taskClose">Cancel</div>
          <div class="card-footer-item roundedbottomright isPointer bluebg10 white txtbold" @click="taskAdd()">Save</div>
        </footer>
      </div>
    </b-modal>

    <b-modal :active.sync="showMeeting" :width="400" scroll="clip" has-modal-card>
      <div class="card modal-card roundedtop roundedbottom">
        <div class="card-header roundedtop bluebg10">
          <div class="card-header-title white" v-if="newMeeting.type === 'meeting'">Meeting</div>
          <div class="card-header-title white" v-else>Availability Slot</div>
        </div>
        <div class="modal-card-body">
          <div class="content">
            <b-select placeholder="Change type" v-model="newMeeting.type" expanded>
              <option value="meeting">Meeting</option>
              <option value="availability">Availability</option>
            </b-select>
            <b-field label="Description">
              <b-input type="textarea" rows="2" v-model="newMeeting.title"></b-input>
            </b-field>
            <!-- attendees: start -->
            <template>
              <div class="mv11 tsk bluebg1">
                <template v-if="newMeeting.type === 'availability'">
                  <b-field label="What type of slot is this?">
                    <b-select placeholder="Slot type" v-model="newMeeting.slottype" expanded>
                      <option value="One to One Session">One to One Session</option>
                      <option value="Group Session">Group Session</option>
                      <option value="Meeting Slot">Meeting Slot</option>
                    </b-select>
                  </b-field>
                  <div style="padding-bottom: 10px;" v-if="newMeeting.slottype === 'Group Session'">
                    <b-field label="How many people can join the slot?">
                      <b-numberinput size="is-small" min="0" v-model="newMeeting.attendees"></b-numberinput>
                    </b-field>
                  </div>
                  <b-field label="Payment model" v-if="newMeeting.slottype !== 'Meeting Slot'">
                    <b-select placeholder="Free or billed" v-model="newMeeting.slotbill" expanded>
                      <option value="Free">Free</option>
                      <option value="per Individual">per Individual</option>
                      <option value="per Group">per Group</option>
                    </b-select>
                  </b-field>
                  <b-field label="Cost" v-if="newMeeting.slottype !== 'Meeting Slot' && newMeeting.slotbill !== 'Free'">
                    <b-input type="number" v-model="newMeeting.slotcost"></b-input>
                  </b-field>
                  <b-field label="Terms" v-if="newMeeting.slottype !== 'Meeting Slot' && newMeeting.slotbill !== 'Free'">
                    <b-input placeholder="Please pay 3 days before the event" v-model="newMeeting.slotbillterm"></b-input>
                  </b-field>
                </template>
                <div class="isFlex">
                  <div class="isFlexGrow">Attendees</div>
                  <div class="iconbutton" @click="selectPerson()" v-if="newMeeting.type === 'meeting'">
                    <font-awesome-icon :icon="['fal', 'id-card']" size="sm"></font-awesome-icon>
                  </div>
                </div>
                <div class="mve tsk" v-if="newMeeting.people && newMeeting.people.length === 0">No attendees</div>

                <div class="isFlex mv3 tsk" v-for="(p, index) in newMeeting.people" :key="index">
                  <div class="isFlexGrow">
                    <div>{{ p.firstname }} {{ p.lastname }}</div>
                    <div><a :href="`mailto:${p.email}`">{{p.email}}</a></div>
                    <div><a :href="`tel:${p.email}`">{{p.mobile}}</a></div>
                  </div>
                  <div class="iconbutton" @click="deletePerson(p, index)">
                    <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                  </div>
                </div>
              </div>
            </template>
            <!-- attendees: end -->

            <!-- when: start -->
            <div v-if="newMeeting.type === 'meeting'">Meeting Time</div>
            <div v-else>What time are you available?</div>
            <div class="isFlex tsk bluebg1">
              <div style="width: 50%; padding-right: 10px;">
                <b-field label="Hour">
                  <b-numberinput size="is-small" min="0" max="23" v-model="newMeeting.hourstart"></b-numberinput>
                </b-field>
              </div>
              <div style="width: 50%; padding-left: 10px;">
                <b-field label="Minutes">
                  <b-numberinput size="is-small" v-model="newMeeting.minutestart" step="5" min="0" max="55"></b-numberinput>
                </b-field>
              </div>
            </div>
            <!-- when: end -->

            <!-- duration: start -->
            <div style="height: 20px;"></div>
            <div v-if="newMeeting.type === 'meeting'">Meeting Duration</div>
            <div v-else>How long is this slot for?</div>
            <div class="isFlex tsk bluebg1">
              <div style="width: 50%; padding-right: 10px;">
                <b-field label="Hour">
                  <b-numberinput size="is-small" min="0" max="23" v-model="newMeeting.meetinghours"></b-numberinput>
                </b-field>
              </div>
              <div style="width: 50%; padding-left: 10px;">
                <b-field label="Minutes">
                  <b-numberinput size="is-small" v-model="newMeeting.meetingminutes" step="15" min="0" max="45">
                  </b-numberinput>
                </b-field>
              </div>
            </div>
            <!-- duration: end -->
            <!-- where: start -->
            <b-field label="Where?">
              <b-select placeholder="Select a name" v-model="newMeeting.where" expanded>
                <option value="Office">Office</option>
                <option value="Away">Away</option>
                <option value="Online External">Online External</option>
              </b-select>
            </b-field>
            <b-field label="Online url" message="Please enter the url for the meeting (Zoom, Hangouts, Teams) plus pins or passwords if needed" v-if="newMeeting.where === 'Online External'">
              <b-input  v-model="newMeeting.confurl"></b-input>
            </b-field>
            <b-field label="Online username or room number" v-if="newMeeting.where === 'Online External'">
              <b-input  v-model="newMeeting.confuser"></b-input>
            </b-field>
            <b-field label="Online PIN" v-if="newMeeting.where === 'Online External'">
              <b-input  v-model="newMeeting.confpin"></b-input>
            </b-field>
            <b-field label="Location" v-else>
              <b-input type="textarea" rows="2" v-model="newMeeting.address"></b-input>
            </b-field>
            <!-- where: end -->
            <!-- travel time: start -->
            <template v-if="newMeeting.where === 'Away'">
              <div>Travel Time</div>
              <div class="isFlex tsk bluebg1">
                <div style="width: 50%; padding-right: 10px;">
                  <b-field label="Hour">
                    <b-numberinput size="is-small" min="0" max="23" v-model="newMeeting.travelhours"></b-numberinput>
                  </b-field>
                </div>
                <div style="width: 50%; padding-left: 10px;">
                  <b-field label="Minutes">
                    <b-numberinput size="is-small" v-model="newMeeting.travelminutes" step="15" min="0" max="45"></b-numberinput>
                  </b-field>
                </div>
              </div>
            </template>
            <!-- travel time: end -->
            <!-- goal: start -->
            <b-field label="Assign to goal" class="mt7">
              <b-select placeholder="Select Goal" expanded v-model="goalSelected" @input="setMeetingGoal()">
                <option v-for="gc in GoalList" :value="gc" :key="gc._id">{{ gc.goaltitle }}</option>
              </b-select>
            </b-field>
            <!-- goal: end -->
          </div>
        </div>
        <footer class="card-footer roundedbottom">
          <div class="card-footer-item roundedbottomleft isPointer bluebg1 bluetxt8" @click="closeMeeting">Cancel</div>
          <div class="card-footer-item roundedbottomright isPointer bluebg10 white txtbold" @click="saveMeeting()">Save</div>
        </footer>
      </div>
    </b-modal>

    <!-- popups: end -->
  </div>
</template>

<script>
import findLastIndex from "lodash/findLastIndex";
import findIndex from "lodash/findIndex";
import remove from "lodash/remove";
import debounce from "lodash/debounce";
import store from "../store";
import ObjectID from "bson-objectid";
import ModalGoals from "@/modals/ModalGoals.vue";
import ModalTask from "@/modals/ModalTask.vue";
import array from "lodash/array";

import { EventBus } from "../event-bus.js";
import { windowSplit2 } from "../mixins/windowSplit.js";
import { dbFunctions } from "../mixins/dbMixins.js";
import { goalsMixins } from "@/mixins/goalsMixins.js";
import { Utils } from "@/mixins/utils.js";
import draggable from "vuedraggable";
import Goal from "@/components/Goal.vue";
import Task from "@/components/Task.vue";
import ModalPeopleList from "@/components/ModalPeopleList.vue";

export default {
  mixins: [Utils, windowSplit2, dbFunctions, goalsMixins],
  name: "Work",
  components: {
    Goal,
    Task,
    draggable
  },
  data() {
    return {
      user: store.state.user,
      PlanMode: false,
      PlanExpanded: false,
      ShowEmpty: false,
      taskList: "rogue",
      TodayRaw: new Date(),
      Today: "",
      TodayTS: 0,
      TodayTxt: "Sun",
      dateStart: "",
      dateStartTS: "",
      dateStartRaw: "",
      dateEnd: "",
      dateEndTS: "",
      dateEndRaw: "",
      daysBack: 3,
      daysForward: 7,
      monthsList: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      weeksList: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      datelist: [],
      calList: [],
      rogueTasks: [],
      inboundTab: 0,
      ActiveCol: {},
      ActiveColIndex: -1,
      DayGoal: '',
      DayGoalText: '',
      // meetings: start
      showMeeting: false,
      newMeeting: {},
      newMeetingMode: "new",
      // meetings: end
      // tasks: start
      showTask: false,
      newTask: {},
      newTaskArea: "rogue",
      // tasks: end
      GoalList: [],
      goalSelected: {
        _id: "all",
        goaltitle: "No Goal Selected",
        goalcolor: "#CCC"
      },
      naviShow: this.$store.state.naviShow,
      expTasks: [],
      workflow: [
        { value: 'New', label: 'New', icon: 'sparkles' },
        { value: 'Active', label: 'Active', icon: 'sparkles' },
        { value: 'Completed', label: 'Completed', icon: 'sparkles' }
      ],
      ingoalsort: false,
      meId: '',
      meInitials: '',
      reloadlist: false,
      cloneActive: false,
      cloneArray: [],
      cloneDay: {},
      previousgoalid: '',
    };
  },

  beforeCreate() {},

  computed: {},

  async created() {
    EventBus.$on("task_saved", data => {
      this.updateTasks(data);
    });
    EventBus.$on("Work_Meeting_Person", data => {
      this.setPerson(data);
    });
    this.setDates(new Date());
    // this.SelectGoalList = await this.GoalsGrouped();
    // window.addEventListener("focus", (event) => { 
    //   if (window.document.location.pathname === '/work') {
    //     this.checkFocus();
    //   }
    // }, false);
  },

  watch: {
    "$store.state.naviShow": function() {
      this.naviShow = this.$store.state.naviShow;
    }
  },

  methods: {
    todayClass: function(day) {
      return {
        "darkgreybg white": this.TodayTxt === day
      };
    },

    completedClass: function(row) {
      return {
        completed: row.workflow === "Completed"
      };
    },

    checkFocus() {},

    setDates(indate) {
      this.TodayRaw = indate;
      const todayfull = this.getDateFull(indate, "none", "none", 0);
      this.Today = todayfull.db;
      this.TodayTS = todayfull.tsm;
      this.TodayTxt = todayfull.dayofweektxtshort;
      const startfull = this.getDateFull(
        indate,
        "subtract",
        "days",
        this.daysBack
      );
      this.dateStart = startfull.db;
      this.dateStartTS = startfull.tsms;
      this.dateStartRaw = startfull.dtraw;
      const endfull = this.getDateFull(indate, "add", "days", this.daysForward);
      this.dateEnd = endfull.db;
      this.dateEndTS = endfull.tsms;
      this.createCal();
    },
    
    async createCal() {
      this.meId = await localStorage.getItem("sub");
      const first = localStorage.getItem("firstname");
      const last = localStorage.getItem("lastname");
      this.meInitials = this.getInitials(`${first} ${last}`);
      this.calList = [];
      this.datelist = [];
      const totaldays = this.daysBack + this.daysForward;
      let useDate = {};
      for (let i = 0; i < totaldays; i++) {
        if (i === 0) {
          useDate = this.getDateFull(this.dateStartRaw, "none", "none", 0);
        } else {
          useDate = this.getDateFull(this.dateStartRaw, "add", "days", i);
        }
        const colObj = {
          row: i,
          dateDB: useDate.db,
          dateDay: useDate.daysingle,
          dateDayTxt: useDate.dayofweektxtshort,
          dateDayTxtLong: useDate.dayofweektxtlong,
          dateDayOfWeek: useDate.dayofweek,
          dateDayOfYear: useDate.dayofyear,
          dateTS: useDate.tsm,
          _id: "",
          events: [],
          showMeeting: false,
          meetingType: 'Meeting',
          loader: true,
          totalhours: 0,
          totalminutes: 0,
          totalblocks: 0,
          clonemode: false,
          clonesource: false,
        };
        this.datelist.push(useDate.db);
        // get tasks
        // this.getCalTasks(useDate.tsm, useDate.db);
        this.calList.push(colObj);
      }
      this.getTasks();
      this.getDays();
    },

    getDays() {
      let rowno = 0;
      for (const d of this.calList) {
        this.getDay(d.dateDB, rowno);
        rowno++;
      }
      setTimeout(() => {
        this.scrollDiv();
      }, 1000);
    },

    scrollDiv() {
      var container = this.$el.querySelector("#scrollcontainer");
      container.scrollLeft = 900;
      container.scrollTop = 0;
    },

    setGoalForDay(goal) {
      this.DayGoal = goal._id;
      this.DayGoalText = goal.goaltitle;
      this.getDays();
    },

    async getDay(day, rowno) {
      this.calList[rowno].loader = true;
      let istoday = false;
      if (day === this.Today) {
        istoday = true;
      }
      const data = {
        dateDB: day,
        completed: false,
        istoday,
        goalid: this.DayGoal,
      };
      // const dayback = await this.livePostPromise("getday", "day", data);
      const dayback = await this.DataSend("post", "/getday", data, "body", false);
      const dd = dayback;
      this.calList[rowno]._id = dd._id;

      let thrs = 0;
      let tmin = 0;
      let tblk = 0;

      for (let i = 0; i < dd.events.length; i++) {
        dd.events[i].hide = false;
        if (this.DayGoal !== '') {
          if (dd.events[i].parent !== this.DayGoal) {
            dd.events[i].hide = true;
          }
        }
        dd.events[i].editWorkFlow = false;
        dd.events[i].editPeople = false;
        dd.events[i].editBlocks = false;
        dd.events[i].blockHeight = dd.events[i].blocks * 25;
        thrs += dd.events[i].hours;
        tmin += dd.events[i].hours * 60;
        tmin += dd.events[i].minutes;
        tblk += dd.events[i].blocks;
        dd.events[i].newblocks = dd.events[i].blocks;
        dd.events[i].newhours = dd.events[i].hours;
        dd.events[i].newminutes = dd.events[i].minutes;
        if (!dd.events[i].useicon) {
          dd.events[i].useicon = "sparkles";
        }
        dd.events[i].ismine = true;
        dd.events[i].selected = false;
        let count = -1;
        let mypos = -1;
        for (const p of dd.events[i].people) {
          count += 1;
          if(p._id === this.meId) {
            mypos = count;
            dd.events[i].mypos = mypos;
            if (p.workflow === undefined) {
              dd.events[i].workflow = 'New';
            } else {
              dd.events[i].workflow = p.workflow;
            }
            if (p.workflow === 'Completed') {
              dd.events[i].completed = true;
            }
          }
        }
        if (dd.parent !== '' && mypos === -1 && dd.events[i].type === 'task') {
          dd.events[i].ismine = false;
        }
      }
      const tsec = tmin * 60;
      const timeback = this.secondsToHours(tsec);
      this.calList[rowno].totalblocks = tblk;
      this.calList[rowno].totalhours = timeback.hours;
      this.calList[rowno].totalminutes = timeback.minutes;
      this.calList[rowno].events = dd.events;
      this.calList[rowno].loader = false;
    },

    processChildren(rows, cnt) {
      for (const t of rows) {
        const newmarg = cnt * 7;
        const usestyle = `margin-left: ${newmarg}px`;
        const nr = {
          _id: t._id,
          level: cnt,
          tasktext: t.tasktext,
          taskdescription: t.taskdescription,
          taskstartdate: t.taskstartdate,
          useicon: t.useicon,
          workflow: t.workflow,
          estblocks: t.estblocks,
          esthours: t.esthours,
          estminutes: t.estminutes,
          goalid: t.goalid,
          goalcolor: t.goalcolor,
          goalname: t.goalname,
          deleted: t.deleted,
          completed: t.completed,
          taskcount: t.taskcount,
          people: t.assignedto,
          editWorkFlow: false,
          editPeople: false,
          editBlocks: false,
          usestyle,
          ismine: false
        };
        if (t.sub === this.sub) {
          nr.ismine = true;
        } else {
          const usertrue = array.findLastIndex(t.assignedto, { '_id': this.sub });
          if (usertrue !== -1) {
            nr.ismine = true;
          }
        }
        if (nr.useicon === 'haykal') {
            nr.useicon = 'sparkles';
          }
        this.expTasks.push(nr);
        this.processChildren(t.steps, cnt + 1);
      }
    },

    async openChildren(row, i) {
      this.expTasks = [];
      row.loading = true;
      const data = {
        parentid: row._id,
        parenttype: "goal",
        deleted: false,
        workflowtype: "ne",
        workflow: "Completed",
        sortby: "childorder"
      };
      const tasks = await this.DataSend("post", "/taskstree", data, "rows");
      const tasklist = [];
      for (const t of tasks) {
        const usestyle = "margin-left: 0px";
        const nr = {
          _id: t._id,
          level: 0,
          tasktext: t.tasktext,
          taskdescription: t.taskdescription,
          taskstartdate: t.taskstartdate,
          useicon: t.useicon,
          workflow: t.workflow,
          estblocks: t.estblocks,
          esthours: t.esthours,
          estminutes: t.estminutes,
          goalid: t.goalid,
          goalcolor: t.goalcolor,
          goalname: t.goalname,
          deleted: t.deleted,
          completed: t.completed,
          taskcount: t.taskcount,
          people: t.assignedto,
          editWorkFlow: false,
          editPeople: false,
          editBlocks: false,
          usestyle,
          ismine: false
        };
        if (nr.useicon === 'haykal') {
          nr.useicon = 'sparkles';
        }
        if (t.sub === this.sub) {
          nr.ismine = true;
        } else {
          const usertrue = array.findLastIndex(t.assignedto, { '_id': this.sub });
          if (usertrue !== -1) {
            nr.ismine = true;
          }
        }
        this.expTasks.push(nr);
        this.processChildren(t.steps, 1);
      }
      row.tasks = this.expTasks;
      row.loading = false;
      row.expanded = true;
    },

    closeChildren(row, i) {
      row.expanded = false;
    },

    changeTaskSelected(row, col) {
      for (const tl of this.Columns[col].list) {
        tl.selected = false;
      }
      row.selected = true;
    },

    async openGoal() {},

    async openTask(task, index, col, edit = false) {
      // this.changeTaskSelected(task, index);

      this.ActiveCol = index;
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) {
        useFull = false;
        useCanCancel = true;
      }
      if (task.dataid) {
        task.deleted = false;
        task.goalcolor = "#EFEFEF";
        task.goalid = task.parent;
        task.goalname = task.parenttext;
        task.tasktext = task.title;
        task._id = task.dataid;
        this.TaskActive = task;
      } else {
        this.TaskActive = task;
      }
      const that = this;
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: that.TaskActive, // this is the row data that gets sent to the modal
          edit,
          callBus: "Modal_Goals_Task" // name of the listner for the data coming in from the modal
        },
        events: {
          taskUpdated: value => {
            if (value.type === "delete") {
              // delete functions
            } else if (value.type === "new") {
              // new here
              const tmd = this.goalProcessAvailable(value.data.assignedto);
              value.data.tbs = tmd.rettbs;
              value.data.tbscnt = tmd.rettbscnt;
              this.Columns[this.ActiveCol].list.push(value.data);
            } else {
              if (task.dataid) {
                task.title = value.data.tasktext;
                task.description = value.data.taskdescription;
                task.colour = value.data.goalcolor;
                task.parent = value.data.goalid;
                task.parenttext = value.data.goalname;
                this.saveCol(col);
              } else {
                that.TaskActive.tasktext = value.data.tasktext;
                that.TaskActive.taskcount = value.data.taskcount;
                that.TaskActive.assignedto = value.data.assignedto;
                that.TaskActive.deleted = value.data.deleted;
              }
              
              // const tmd = this.goalProcessAvailable(value.data.assignedto);
              // that.TaskActive.tbs = tmd.rettbs;
              // that.TaskActive.tbscnt = tmd.rettbscnt;
            }
          }
        }
      });
    },

    // old shit

    updateTasks(row) {
      let changesmade = false;
      const inid = row._id;
      let rowno = 0;
      for (const d of this.calList) {
        const daytxt = d.dateDayTxtLong.toLowerCase();
        const settingfield = `${daytxt}settings`;
        const insettings = row[settingfield];
        if (insettings.hours === null) {
          insettings.hours = 0;
        }
        if (insettings.minutes === null) {
          insettings.minutes = 0;
        }
        if (insettings.hours === 0 && insettings.minutes === 0) {
          insettings.minutes = 15;
        }
        let belongs = false;
        const found = 0;
        if (row.durantiontype === "daily") {
          // check to see if it belongs on cal
          belongs = row[daytxt];
          // if it doesnt belong delete it
          if (!belongs) {
            // remove duplicates
            const evens = remove(d.events, function(t) {
              return t.dataid == inid;
            });
          } else {
            const tcheck = findIndex(d.events, { dataid: inid });
            if (tcheck === -1) {
              const newcalobj = { ...this.dayobj };
              newcalobj.calid = this.getUUID(true);
              newcalobj.dataid = inid;
              newcalobj.type = "task";
              newcalobj.duration = "repeat";
              newcalobj.subtype = row.taskcat;
              newcalobj.title = row.tasktext;
              newcalobj.parent = row.goalid;
              newcalobj.parenttext = row.goalname;
              newcalobj.blocks =
                (insettings.hours * 60 + insettings.minutes) / 15;
              newcalobj.hours = insettings.hours;
              newcalobj.minutes = insettings.minutes;
              newcalobj.steps = row.steps;
              newcalobj.tasks = [];
              newcalobj.useStyle = row.goalcolor;
              newcalobj.expanded = false;
              newcalobj.completed = false;
              d.events.push(newcalobj);
            }
          }
        } else {
          belongs = true;
          if (row.deleted) {
            belongs = false;
          }
          if (!belongs) {
            // remove duplicates
            const evens = remove(d.events, function(t) {
              return t.dataid == inid;
            });
          }
        }

        // if it belongs check if in array
        // if not in array add it
        // if in array update it
        for (const e of d.events) {
          if (inid === e.dataid) {
            e.subtype = row.taskcat;
            e.title = row.tasktext;
            e.parent = row.goalid;
            e.parenttext = row.goalname;
            e.steps = row.steps;
            e.useStyle = row.goalcolor;
          }
        }
        rowno++;
      }
    },

    async updateCalTasks(inrow, col) { // needed
      let useid = "";
      if (inrow._id) {
        useid = inrow._id;
      }
      if (inrow.dataid) {
        useid = inrow.dataid;
      }
      const inwf = inrow.workflow;
      let useIcon = "sparkles";
      const wfi = findLastIndex(this.workflow, { value: inwf });
      if (wfi !== -1) {
        useIcon = this.workflow[wfi].icon;
        inrow.useicon = this.workflow[wfi].icon;
      }
      if (inwf === "Completed") {
        inrow.completed = true;
      } else {
        inrow.completed = false;
      }
      if (inrow.people.length !== 0) {
        const usertrue = array.findLastIndex(inrow.people, { '_id': this.meId });
        if (usertrue != -1) {
          inrow.people[usertrue].workflow = inwf;
          this.saveCol(col, inrow.dataid);
        }
      } else {
        const newdoc = {
          _id: useid,
          workflow: inwf,
          useicon: useIcon
        };
        // const savetask = this.updata("tasks", newdoc, "none", "none", {}, false);
        const savetask = await this.DataSend("post", "/task/updatefew", newdoc);
        this.saveCol(col);
      }
      
      inrow.editWorkFlow = false;
    },

    updateDemo(row, col, index) {
      this.ActiveColIndex = index;
      if (row.completed) {
        row.workflow = 'Completed';
        this.reloadlist = true;
      } else {
        row.workflow = 'Active';
        this.reloadlist = false;
      }
      this.saveCol(col);
    },

    updateWorkflow(row, col, fromcheck, index) {
        this.ActiveColIndex = index;
        let usewf = row.workflow;
        if (fromcheck) {
          if (row.completed) {
            if (row.duration === 'succession') {
              this.reloadlist = true;
            }
            usewf = 'Completed';
          } else {
            usewf = 'Active';
            this.reloadlist = false;
          }
          row.workflow = usewf;
          if (col !== null) { // cal task
            if (row.mypos !== -1) {
              row.people[row.mypos].workflow = usewf;
              const data = {
                day: col.dateDB,
                dataid: row.dataid,
                mypos: row.mypos,
                workflow: usewf
              }
              this.saveCol(col, data);
            } else {
              this.saveCol(col);
              if(row.parent === '') {
                const hasrecord = array.findLastIndex(this.rogueTasks, { '_id': row.dataid });
                if (hasrecord !== -1) {
                  this.rogueTasks[hasrecord].completed = row.completed;
                  this.rogueTasks[hasrecord].workflow = row.workflow;
                  const tdata = {
                    _id: row.dataid,
                    completed: row.completed,
                    workflow: row.workflow
                  };
                  this.DataSend('post', '/task/updatefew', tdata);
                }
              }
            }
          } else { // rogue tasks
            const tdata = {
              _id: row._id,
              completed: row.completed,
              workflow: row.workflow
            };
            this.DataSend('post', '/task/updatefew', tdata);
            for (const r of this.calList) {
              const hasrecord = array.findLastIndex(r.events, { 'dataid': row._id });
              if (hasrecord !== -1) {
                const urow = r.events[hasrecord];
                urow.completed = row.completed;
                urow.workflow = row.workflow;
                this.saveCol(r);
              }
            }
          }
        } else {
          if (row.mypos !== -1) {
            row.people[row.mypos].workflow = usewf;
            const data = {
              day: col.dateDB,
              dataid: row.dataid,
              mypos: row.mypos,
              workflow: usewf
            }
            // this.DataSend('post', '/updateworkflow', data);
            if(usewf === 'Completed') {
              row.completed = true;
            } else {
              row.completed = false;
            }
            this.saveCol(col, data);
          } else {
            this.saveCol(col);
            if(row.parent === '') {
              const hasrecord = array.findLastIndex(this.rogueTasks, { '_id': row.dataid });
              if (hasrecord !== -1) {
                this.rogueTasks[hasrecord].completed = row.completed;
                this.rogueTasks[hasrecord].workflow = row.workflow;
                const tdata = {
                  _id: row.dataid,
                  completed: row.completed,
                  workflow: row.workflow
                };
                this.DataSend('post', '/task/updatefew', tdata);
              }
            }
          }
        }
        row.editWorkFlow = false;
    },

    async updateCalSteps(inrow, tskid) {
      const newWF = inrow.workflow;
      let newWFI = "sparkles";
      const wfi = findLastIndex(this.workflow, { value: inrow.workflow });
      if (wfi !== -1) {
        newWFI = this.workflow[wfi].icon;
      }
      let tasksteps = [];
      for (const c of this.calList) {
        let found = 0;
        // main steps
        for (const e of c.events) {
          // main card
          if (e.dataid === tskid) {
            found++;
            for (const s of e.steps) {
              // if(s.steptext)
              if (s.steptext === inrow.steptext) {
                s.workflow = newWF;
                s.useicon = newWFI;
              }
            }
          }
          // tasks
          for (const t of e.tasks) {
            if (t._id === tskid) {
              for (const st of t.steps) {
                if (st.steptext === inrow.steptext) {
                  st.workflow = newWF;
                  st.useicon = newWFI;
                }
              }
              tasksteps = t.steps;
              found++;
            }
          }
        }
        if (found !== 0) {
          this.saveCol(c);
        }
      }

      // save the task
      const newdoc = {
        _id: tskid,
        workflow: newWF,
        useicon: newWFI,
        steps: tasksteps
      };
      const savetask = this.DataSend("put", "/task/updatefew", newdoc);
    },

    async getTasks() {
      // get on cal tasks
      const caltasks = await this.DataGet("/tasks/rogue/active");
      for (const t of caltasks) {
        t.taskTypeIcon = "check-square";
        if (t.durantiontype === "daily") {
          t.taskTypeIcon = "repeat";
        }
        if (t.useicon === 'haykal') {
          t.useicon = 'sparkles';
        }
      }
      this.rogueTasks = caltasks;
    },

    async getCalTasks(ts, dt, row) {
      // get recurring tasks
      const tarray = [];
      const query = {
        $and: [{ deleted: false }, { "ondates.dt": dt }]
      };
      const options = {
        fields: {
          blocksdaily: 1,
          client: 1,
          durantiontype: 1
        }
      };
      const caltask = await this.getData(
        "tasks",
        query,
        {},
        "none",
        "none",
        "searchextended"
      );
      for (const t of caltask) {
        t.order = 0;
        tarray.push(t);
      }
    },

    changePlan() {
      if (this.PlanMode) {
        this.PlanMode = false;
        this.ShowEmpty = false;
      } else {
        this.PlanMode = true;
        this.ShowEmpty = false;
      }
    },

    expandPlan() {
      if (this.PlanExpanded) {
        this.PlanExpanded = false;
      } else {
        this.PlanExpanded = true;
      }
    },

    changeTaskList(area) {
      if(this.taskList !== area) {
        this.PlanMode = false;
        this.ShowEmpty = false;
      }
      this.taskList = area;
      this.changePlan();
    },

    colClone(row) {
      const newcalobj = { ...this.dayobj };
      newcalobj.calid = this.getUUID(true);
      newcalobj.dataid = row._id;
      newcalobj.type = "task";
      newcalobj.duration = 'once';
      newcalobj.subtype = row.taskcat;
      newcalobj.title = row.tasktext;
      newcalobj.description = row.taskdescription;
      newcalobj.parent = row.goalid;
      newcalobj.parenttext = row.goalname;
      newcalobj.blocks = row.estblocks;
      newcalobj.hours = row.esthours;
      newcalobj.minutes = row.estminutes;
      newcalobj.steps = row.steps;
      newcalobj.tasks = [];
      newcalobj.workflow = row.workflow;
      newcalobj.editWorkFlow = false;
      newcalobj.editPeople = false;
      newcalobj.editBlocks = false;
      newcalobj.ismine = true;
      const wfi = findLastIndex(this.workflow, { value: row.workflow });
      if (wfi !== -1) {
        newcalobj.useicon = this.workflow[wfi].icon;
      }
      newcalobj.mypos = -1;
      let count = -1;
      let mypos = -1;
      if (row.people) {
        newcalobj.people = row.people;
        for (const p of row.people) {
          count += 1;
          if(p._id === this.meId) {
            mypos = count;
            newcalobj.mypos = mypos;
            if (p.workflow === undefined) {
              newcalobj.workflow = 'New';
            } else {
              newcalobj.workflow = p.workflow;
            }
          }
        }
      }
      if (mypos === -1) {
        const inme = this.GetMeLocal();
        const nr = {
          approve: false,
          complete: false,
          email: "",
          resources: false,
          review: false,
          username: inme.subname,
          worker: true,
          _id: inme.sub,
        };
        newcalobj.people.push(nr);
      }
      newcalobj.colour = row.goalcolor;
      newcalobj.expanded = false;
      newcalobj.completed = false;
      return newcalobj;
    },

    goalClone(row) {
      const newglobj = { ...this.dayobj };
      newglobj.calid = this.getUUID(true);
      newglobj.dataid = row._id;
      newglobj.type = "project";
      newglobj.duration = "once";
      newglobj.subtype = "";
      newglobj.title = row.goaltitle;
      newglobj.parent = row.childof;
      newglobj.parenttext = "";
      newglobj.blocks = 3;
      newglobj.hours = 0;
      newglobj.minutes = 45;
      newglobj.steps = [];
      newglobj.tasks = [];
      newglobj.workflow = "New";
      const wfi = findLastIndex(this.workflow, { value: "New" });
      if (wfi !== -1) {
        newglobj.useicon = this.workflow[wfi].icon;
      }
      newglobj.colour = row.goalcolor;
      newglobj.expanded = false;
      newglobj.completed = false;
      newglobj.editWorkFlow = false;
      newglobj.editPeople = false;
      newglobj.editBlocks = false;
      return newglobj;
    },
    changeToClone(col, index) {
      // clonemode: false,
      // clonesource: false,
      this.cloneDay = col;
      let cnt = -1;
      for (const cr of this.calList) {
        cnt += 1;
        cr.clonemode = true;
        if (cnt === index) {
          cr.clonesource = true;
        }
      }
      if (col !== null) {

      }
    },

    cancelClone(col, index) {
      for (const cr of this.calList) {
        cr.clonemode = false;
        cr.clonesource = false;
      }
      for (const ev of col.events) {
        ev.selected = false;
      }
    },

    cloneItems(c, index) {
      for (const ev of this.cloneDay.events) {
        if (ev.selected) {
          const newev = {...ev};
          newev.selected = false;
          this.cloneDay.calid = new ObjectID().str;
          c.events.push(newev);
        }
      }
      this.saveCol(c);
    },

    async projectGetTasks(id) {
      const query = { goalid: id };
      const options = { limit: 5 };
      const gett = await this.getData(
        "tasks",
        query,
        options,
        "none",
        "none",
        "searchextended"
      );
    },

    colChanged(evt, col) {
      this.saveCol(col);
    },

    updateTime(row) {
      // const tmin = row.newblocks * 15;
      // const tsec = tmin * 60;
      // const timeback = this.secondsToHours(tsec);
      // row.newhours = timeback.hours;
      // row.newminutes = timeback.minutes;
    },

    updateBlocks(row, col) {
      // const tmin = row.newblocks * 15;
      // const tsec = tmin * 60;
      // const timeback = this.secondsToHours(tsec);
      // row.blocks = row.newblocks;
      // row.hours = timeback.hours;
      // row.minutes = timeback.minutes;
      // row.blockHeight = row.blocks * 20;
      // row.editBlocks = false;
      this.saveCol(col);
      row.editBlocks = false;
    },

    async saveCol(col, upw = null) {
      let thrs = 0;
      let tmin = 0;
      let tblk = 0;
      for (let i = 0; i < col.events.length; i++) {
        thrs += col.events[i].hours;
        tmin += col.events[i].hours * 60;
        tmin += col.events[i].minutes;
        tblk += col.events[i].blocks;
        delete col.events[i].selected;
      }
      const tsec = tmin * 60;
      const timeback = this.secondsToHours(tsec);
      col.totalblocks = tblk;
      col.totalhours = timeback.hours;
      col.totalminutes = timeback.minutes;

      const newDoc = {
        _id: col._id,
        dayts: col.dateTS,
        events: col.events,
        totalblocks: col.totalblocks,
        totalhours: col.totalhours,
        totalminutes: col.totalminutes
      };
      await this.DataSend("post", "/savecal", newDoc, '', false);
      if(upw !== null) {
        this.DataSend('post', '/updateworkflow', upw, '', false);
      }
      if (this.reloadlist) {
        this.getDay(col.dateDB, this.ActiveColIndex);
      }
    },

    removeColItem(col, element, index, key) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this item This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.calList[index].events.splice(key, 1);
          this.saveCol(this.calList[index]);
        }
      });
    },

    async colExpand(row, id) {
      if (row.type === "project") {
        const query = { goalid: row.dataid, workflow: { $ne: "Completed" } };
        const options = {
          fields: {
            tasktext: 1,
            durantiontype: 1,
            goalid: 1,
            deleted: 1,
            estblocks: 1,
            estminutes: 1,
            grindhours: 1,
            grindminutes: 1,
            grindmonths: 1,
            grindweeks: 1,
            grindyears: 1,
            grinddays: 1,
            estblocks: 1,
            esthours: 1,
            estminutes: 1,
            workflow: 1,
            useicon: 1,
            tasktilldate: 1,
            taskstartdate: 1,
            tasktilldatets: 1,
            taskstartdatets: 1,
            childorder: 1,
            workflow: 1,
            steps: 1,
            goalid: 1,
            goalname: 1,
            goalcolor: 1
          },
          sort: [["childorder", "asc"]],
          limit: 5
        };
        const gett = await this.getData(
          "tasks",
          query,
          options,
          "none",
          "none",
          "searchextended"
        );
        for (const t of gett) {
          t.expanded = false;
          t.editWorkFlow = false;
          t.editBlocks = false;
          for (const s of t.steps) {
            s.expanded = false;
            s.editWorkFlow = false;
            s.editBlocks = false;
          }
        }
        row.tasks = gett;
      }
      row.expanded = true;
    },

    colClose(row, id) {
      row.expanded = false;
      row.editWorkFlow = false;
      row.editBlocks = false;
    },

    peopleExpand(row) {
      if (row.editPeople) {
        row.editPeople = false;
      } else {
        row.editPeople = true;
      }
    },

    async workflowExpand(row) {
      if (row.editWorkFlow) {
        row.editWorkFlow = false;
      } else {
        if (row.parent === '') {
          this.workflow = [
            { value: 'New', label: 'New', icon: 'sparkles' },
            { value: 'Active', label: 'Active', icon: 'sparkles' },
            { value: 'Completed', label: 'Completed', icon: 'sparkles' }
          ]
        } else {
          const getw = await this.DataGet(`/goal/workflow/${row.parent}`);
          this.workflow = getw;
        }
        row.editWorkFlow = true;
      }
    },
    editBlocksExpand(row) {
      if (row.editBlocks) {
        row.editBlocks = false;
      } else {
        row.editBlocks = true;
      }
    },

    // add a task: start
    taskAddOpen(area, day) {
      this.ActiveCol = day;
      this.newTaskArea = area;
      this.newTask = { ...this.task };
      const id = new ObjectID();
      const idout = id.str;
      this.newTask._id = 'new';
      this.newTask.estminutes = 15;
      this.newTask.sub = this.user.sub;
      this.newTask.subname = this.user.subname;
      this.showTask = true;
    },

    taskClose() {
      this.showTask = false;
    },

    setTaskGoal() {
      if (this.goalSelected._id !== "all") {
        this.newTask.goalid = this.goalSelected._id;
        this.newTask.goalname = this.goalSelected.goaltitle;
        this.newTask.goalcolor = this.goalSelected.goalcolor;
      }
    },

    async taskAdd() {
      if (this.newTask.tasktext === "") {
        this.$buefy.snackbar.open({
          message: "Please describe your task",
          position: "is-top"
        });
        return;
      }

      // process blocks
      const totalmins = this.newTask.esthours * 60 + this.newTask.estminutes;
      this.newTask.estblocks = totalmins / 15;
      // const newAss = {
      //   _id: this.user.sub,
      //   firstname: this.user.subname,
      //   role: "Doer",
      //   workflow: "New",
      //   completed: false
      // };
      // this.newTask.assignedto.push(newAss);

      let usegoalid = '';

      if (this.newTask.goalid !== "") {
        usegoalid = this.newTask.goalid;
        this.previousgoalid = this.newTask.goalid;
      } else {
        if (this.previousgoalid !== '') {
          usegoalid = this.previousgoalid;
        }
      }

      if (usegoalid !== "") {
        const query = { _id: usegoalid };
        const options = {};
        const getGoal = await this.DataGet(`/goal/${usegoalid}`)
        const usegoal = getGoal;
        this.newTask.goalcolor = usegoal.goalcolor;
        this.newTask.goalid = usegoalid;
        this.newTask.goalname = usegoal.goaltitle;
        this.newTask.availableto = usegoal.availableto;
        this.newTask.clientid = usegoal.clientid;
        this.newTask.client = usegoal.client;
      }

      // this.newTask

      const addt = await this.DataSend("put", "/task", this.newTask);
      const tsk = addt.rows;
      this.newTask._id = tsk._id;
      if (this.newTask.goalid === "") {
        this.rogueTasks.unshift(this.newTask);
      }
      if (this.newTaskArea === "day") {
        const newcalitem = this.colClone(this.newTask);
        this.ActiveCol.events.push(newcalitem);
        this.saveCol(this.ActiveCol);
      }
      this.taskClose();
    },
    // add a task: end

    async deleteTask(goal, task) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this task?",
        confirmText: "Delete Task",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          task.deleted = true;
          const deltask = await this.DataDelete(`/task/${task._id}`, "row");
          // this.$buefy.toast.open("Task Deleted");
        }
      });
    },
  }
};
</script>

<style>
.planlist {
  width: 500px;
}
.step {
  font-size: 0.8em;
}
.stepdone {
  font-size: 0.8em;
}
.task {
  background-color: #e8e8f0;
  min-width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
}
.project {
  background-color: #e8e8f0;
  min-width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
}
.meeting {
  background-color: #cc0000;
  color: white;
  min-width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
}
.availability {
  background-color: #05ac13;
  color: white;
  min-width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
}
.completed {
  opacity: 0.3;
}
</style>
